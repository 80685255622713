import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect } from 'react-redux';
import { LEFTRIGHT_PADDING } from "../config/style.config";
import LoadingPage from "../components/Loading";
import NoPermission from './NoPermission';
import PageSelector from '../components/PageSelector';
import ListViewSvg from '../svgs/camera/ListView';
import GridViewSvg from '../svgs/camera/GridView';
import SmallSearchSvg from '../svgs/watchPage/SmallSearchSvg'
import TitleLeftSvg from '../svgs/TitleLeftSvg'
import EditIconSvg from "../svgs/camera/EditIcon";
import AlertPhotoSvg from '../svgs/camera/AlertPhotoSvg'
import AlertVideoSvg from '../svgs/camera/AlertVideoSvg'
import { fetchDangerZones } from "../services/dangerZones.service";
import BinSvg from '../svgs/camera/BinSvg'
import PlayVideoSvg from '../svgs/camera/PlayVideo'
import { Login } from "../actions/actions";
import { fetchAICameras, fetchCameraEvent } from "../services/cameras.service";
import AlertSnapshot from '../components/camera/AlertSnapshot'
import AlertSnapshotPopup from '../components/camera/AlertSnapshotPopup';
import { yearMonthDay,handleFilterDate } from "../actions/actionsDateTime";
import DropDown from '../components/DropdownMenu'
import { deleteWatchAlert } from "../services/watches.service";
import AlertVideoPopup from "../components/camera/AlertVideoPopup";
import { server_ip } from "../config";
//

const ALERT_TYPE_DICT={
    'CAMERA_DNG_ZONE':'Danger Zone',
    'CAMERA_NO_JACKET':'No Jactket',
    'CAMERA_NO_HELMET':'No Helmet',
    'CAMERA_FALLDOWN':'Falldown'
}
const typeToVal = {
        "Danger Zone": 'CAMERA_DNG_ZONE',
        "No Jacket": 'CAMERA_NO_JACKET',
        "No Helmet": 'CAMERA_NO_HELMET',
        "FallDown": 'CAMERA_FALLDOWN',
    }
function Component(props){
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [isLoading,setisLoading] = useState(false)
    const [popType, setPopType] = useState(undefined)
    const filterDate = (searchParams.get('date') || 'Today')
    const filterFrom = searchParams.get('from')
    const filterTo = searchParams.get('to')
    const filterType = searchParams.get('type')
    const fromRef = useRef(null)
    const toRef = useRef(null)
    function handleParamChange(key, val){
        searchParams.delete(key)
        if(['from','to'].includes(key)) searchParams.delete('date')
        if(key === 'date'){
            searchParams.delete('from')
            searchParams.delete('to') 
        }
        if(key === 'clearDate') {
            searchParams.delete('from')
            searchParams.delete('to')
            searchParams.delete('date')
            fromRef.current.value = ""
            toRef.current.value = ""
        }
        if(val !== '' && key !== 'clearDate') searchParams.set(key, val)
        setSearchParams(searchParams)
    }
    function handleDateChange(val){
        if(val !== 'Select Date') return handleParamChange('date', val)
        setPopType('date')
    }
    function handleTypeChange(val){
        if(val === 'All'){
            searchParams.delete('type')
            return setSearchParams(searchParams)
        }
        return handleParamChange('type', val)
    }

    const [pageDict,setPageDict]=useState({})
    //paging
    const page =  parseInt(searchParams.get('page'))||1;
    const limit=99;
    const [total,setTotal]=useState(0)
    //const [activeTotal,setactiveTotal]=useState(0)
    const totalPage = Math.ceil(total/limit)||1
    const handleSelectPage=(zoneId)=>(p)=>async ()=>{
        let tp=Math.ceil((data.filter(z=>z._id===zoneId)?.[0]?.total||1)/10)||1
        if(p > tp || p < 1) return;
        setPageDict({...pageDict,[zoneId]:p})

        let d = [...data]
        for(let zone of d){
            const filter={ projectId:props.control?.projectId }
            if(filterType)filter["type"] = typeToVal[filterType]
            if(filterDate) filter['time'] = handleFilterDate(filterDate)
            if(filterFrom && filterTo) filter['time'] = handleFilterDate('', filterFrom, filterTo)
            if(zone._id===zoneId){
                let respond =await fetchCameraEvent(props.token?.token,{...filter,deviceID:{$in:zone?.data?.cameras||[]}},{ skip: 10*((p)-1), limit:10 , sort:{time:-1}})
                let events = respond?.result||[]
                zone.events=events
                zone.total=respond?.count||0
            }
        }
        setData(d);

    }
    //end of paging

    const [isGridView,setisGridView]=useState(true)
    const [data,setData]=useState([])
    const sortBy={'data.id':1};
    const fetchData=async(p=page,l=limit,st=sortBy)=>{
        const filter={ projectId:props.control?.projectId }
        let result = await fetchDangerZones(props.token.token,filter,{ skip: l*(p-1), limit:l , sort:st})
        if(result.errno===10001){
            props.dispatch(Login(false))
            return alert('Session Expired');
        }else if(result?.result){

            const filter={ projectId:props.control?.projectId }
            if(filterType)filter["type"] = typeToVal[filterType]
            if(filterDate) filter['time'] = handleFilterDate(filterDate)
            if(filterFrom && filterTo) filter['time'] = handleFilterDate('', filterFrom, filterTo)

            for(let zone of result?.result){
                let respond =await fetchCameraEvent(props.token?.token,{...filter,deviceID:{$in:zone?.data?.cameras||[]}},{ skip: 10*((pageDict?.[zone._id]||1)-1), limit:10 , sort:{time:-1}})
                let events = respond?.result||[]
                zone.events=events
                zone.total=respond?.count||0
            }

            setData(result?.result);
            setTotal(result?.count||0)
            //setactiveTotal(result?.activeCount||0)
        }
    }

    //snapshot popup
    const [isSnapshot,setisSnapshot]=useState(null)
    const handleShowPopup=(item,index)=>()=>{
        setisSnapshot(item)
    }
    const handleClosePopup=()=>{setisSnapshot(null)}
    //end of snapshot popup

    //video popup
    const [isAlertVideo,setisAlertVideo]=useState(null)
    const handleShowVideoPopup=(item,index)=>()=>{
        setisAlertVideo(item)
    }
    const handleCloseVideoPopup=()=>{setisAlertVideo(null)}
    //end of video popup

    useEffect(() => {
        const timer = setTimeout(() => {fetchData()}, 500)
        return () => clearTimeout(timer)
    },[searchParams])

    useEffect(()=>{
        searchParams.delete('page')
        searchParams.set('page', 1)
        setSearchParams(searchParams)
    },[props.control.projectId])



    if(!props.control?.permissions?.DZ_VIEW)
        return(<NoPermission/>)
    return(
        <div className="flex-col bg-[#0C0D14] flex-1 text-white text-[14px]">
            {popType === 'date' && <div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)', zIndex:99}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[350px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                    <div className='flex flex-row items-center space-between w-full'>
                        <div className='font-bold'>Select a date range*</div>          
                    </div>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={() => {setPopType (false)}}>X</div>         
                    <div className='flex flex-row my-4 w-full justify-around'>
                        <div className='w-[50px]'>From:</div>   
                        <input type='date' ref={fromRef} defaultValue={filterFrom} max={filterTo || yearMonthDay(new Date())} onChange={(e) => {handleParamChange('from', e.target.value)}} className='border rounded-full px-2  min-w-[180px]'style={{colorScheme: 'dark'}}></input>    
                    </div>
                    <div className='flex flex-row mb-4 w-full justify-around'>
                        <div className='w-[50px]'>To:</div>   
                        <input type='date' ref={toRef} defaultValue={filterTo} min={filterFrom || undefined} onChange={(e) => {handleParamChange('to', e.target.value)}} className='border rounded-full px-2  min-w-[180px]'style={{colorScheme: 'dark'}}></input>    
                    </div>
                    <div className='w-full flex flex-row items-center justify-between gap-2'>
                        <div className='button_blue w-[50%]' onClick={() => {setPopType(false)}}>Confirm</div>
                        <div className='button_blue w-[50%] text-white' onClick={() => {handleParamChange('clearDate')}}>Clear</div>
                    </div>
                </div>
            </div>}
           
            <div style={{...LEFTRIGHT_PADDING}} className='pb-64'>

                <div className="flex flex-row mt-[40px]">
                    <TitleLeftSvg className='mr-[9px]'/>
                    <div className="text-[24px] text-white font-bold ">Live view</div>
                </div>
                

                 {/*header/buttons/filter*/}
                 <div className="flex flex-row items-center justify-between mt-[27px]">
                    <div className="flex flex-row items-center">
                        <div className="subpage_not_selected" onClick={() => navigate('/danger-zone')}>Danger Zone</div>
                        <div className="subpage_selected">Alert</div>
                    </div>
                    <div className="flex flex-row items-center gap-2 flex-1 justify-end"> 
                        <div onClick={()=>{setisGridView(true)}} className="flex flex-row items-center cursor-pointer gap-[4px] select-none" style={{color:isGridView?'#63BDFF':'white'}}>
                            <GridViewSvg color={isGridView?'#63BDFF':'white'}/>
                            Grid View
                        </div>
                        |
                        <div onClick={()=>{setisGridView(false)}} className="flex flex-row items-center cursor-pointer gap-[4px] select-none" style={{color:!isGridView?'#63BDFF':'white'}}>
                            <ListViewSvg color={!isGridView?'#63BDFF':'white'}/>
                            List View
                        </div>

                        <div className="flex flex-row items-center gap-4 justify-end text-white"> 
                            <DropDown value={(filterFrom && filterTo ? `${filterFrom} / ${filterTo}` :filterDate)} select = {handleDateChange}/>
                        </div>
                    </div>
                </div>
                {/*end of header/buttons/filter*/}

                {data.map((zone,zoneIndex)=><div key={ zone?._id||zoneIndex} className='w-full flex flex-row gap-[24px] items-stretch mt-5 mb-10'>
                    <div className='flex flex-col justify-start' style={{flex:3}}>
                        <img src='/temp2.png' style={{objectFit:'contain',aspectRatio:1672/1512,height:undefined,width:'100%'}} alt='danger zone'/>
                    </div>

                    <div className=""style={{flex:6}}>
                        {/*main list/grid*/}
                        {isGridView&&
                            <div className="flex-wrap	flex flex-row gap-[16px] items-center justify-stretch mb-[64px]">
                                {zone?.events?.map((item,index) => 
                                    <div key={item?._id||index} className="inline-block py-[16px] px-[24px]" style={{border: '0.5px solid #FFFFFF80',width:'calc(50% - 8px)'}}>
                                        <div className="flex flex-row items-center justify-between">
                                            <div className="flex flex-row items-center text-white">
                                                <TitleLeftSvg className='mr-[9px]'/>
                                                {zone.id} / {item?.name} ({item?.deviceID})
                                            </div>
                                            <div className="flex flex-row items-center gap-2">
                                                <div className="flex flex-row items-center rounded-full px-[16px] gap-[4px] py-[2px] bg-[#FFFFFF12]">
                                                    Alert Type: 
                                                    <div className="text-[#FFE454]">{ALERT_TYPE_DICT[item?.type]}</div>
                                                </div>
                                                {/* {props.control?.permissions?.SUMMARY_HIDE_ALERT && <div className="flex flex-row items-center">
                                                    <BinSvg className='cursor-pointer' onClick={() => setTargetDelete({...item,index})} />
                                                </div>} */}
                                                {/* <div className="button_blue">
                                                    <EditIconSvg width={12}/>
                                                    Edit
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="flex flex-row items-center gap-2 text-white mt-[4px] text-[10px] text-[#ddd] font-[300]">
                                            {/* <CalenderSvg /> */}
                                            {new Date(item?.time).toLocaleString()}
                                        </div>
                                        <div className="flex flex-row items-center gap-[16px] mt-[32px] w-full">
                                            <div className="flex-1 p-[8px]" style={{border:'.5px solid #ffffff80'}}>
                                                <AlertSnapshot deviceId={item?.deviceID} time={item?.time}/>
                                            </div>
                                            <div className="flex-1 p-[8px] relative flex items-center justify-center" style={{border:'.5px solid #ffffff80'}}>
                                                <div className='w-full text-white flex items-center justify-center' style={{height:undefined,aspectRatio:1920/1080}}>
                                                    {(item.video)?
                                                        <video src={`${server_ip}/api/documents/${item.video}`} className="w-full h-full" controls={true} autoPlay={false}/>
                                                        :<div className="">
                                                            No Video
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                        {/*main list/grid*/}
                        {!isGridView&&
                            <div className="flex-wrap	flex flex-row gap-[16px] items-center justify-stretch w-full mb-10" style={{borderBottom:'.5px solid #ffffff40'}}>
                                <div className="flex flex-row items-center text-white w-full pb-[24px]" style={{borderBottom:'.5px solid #ffffff40'}}>
                                    <TitleLeftSvg className='mr-[9px]'/>
                                    {zone.id}
                                </div>
                                <div className="py-[32px] w-full">
                                    <div className="flex flex-row text-[10px] font-bold w-full items-center px-[16px] mb-[16px]">
                                        <div className="flex-[1]">Item</div>
                                        <div className="flex-[2]">Camera Id</div>
                                        <div className="flex-[2]">Camera Name</div>
                                        <div className="flex-[2]">Date & Time</div>
                                        <div className="flex-[2]">Alert Type</div>
                                        <div className="flex-[2]">Snapshot</div>
                                        <div className="flex-[2]">Alert Video</div>
                                        {/* <div className="w-[100px]"/> */}
                                    </div>
                                    {zone?.events?.map((item, idx) =>
                                        <div key={item?._id||idx} className="text-[14px] flex flex-row py-[16px] px-[16px]" style={{overflowWrap: 'break-word',backgroundColor:idx%2===1?'#FFFFFF0D':'transparent'}}>
                                            <div className="flex-[1]">{limit*(page-1)+idx+1}</div>
                                            <div className="flex-[2]">{item?.deviceID}</div>
                                            <div className="flex-[2]">{item?.name}</div>
                                            <div className="flex-[2]">{new Date(item?.time).toLocaleString()}</div>
                                            <div className="flex-[2]">{ALERT_TYPE_DICT[item?.type]||item?.type}</div>
                                            <div className="flex-[2] flex flex-row items-center gap-[4px] cursor-pointer"  onClick={handleShowPopup(item,idx)}>Snapshot <AlertPhotoSvg/></div>
                                            <div className="flex-[2] flex flex-row items-center gap-[4px] cursor-pointer" onClick={handleShowVideoPopup(item,idx)}>Alert Video <AlertVideoSvg/></div>
                                            {/* {props.control?.permissions?.SUMMARY_HIDE_ALERT && <div className="w-[100px] flex flex-row items-center">
                                                <BinSvg className='cursor-pointer' onClick={() => setTargetDelete({...item,idx})} />
                                            </div>} */}
                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                        {/*end of main list/grid*/}
                                        
                
                        <PageSelector
                            handleSelectPage={handleSelectPage(zone._id)}
                            page={pageDict[zone._id]||1}
                            total={zone.total||0}
                            totalPage={ Math.ceil(zone.total/10)||1}
                        />
                    </div>
                </div>
                )}
            </div>


            {/*snapshot popup*/}
            {isSnapshot&&<AlertSnapshotPopup close={handleClosePopup} time={isSnapshot?.time} deviceId={isSnapshot?.deviceID}/>}
            {isAlertVideo&&<AlertVideoPopup close={handleCloseVideoPopup} data={isAlertVideo}/>}
            {/*end of snapshot popup*/}
            {isLoading&&<LoadingPage/>}
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Component)


