import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { useSearchParams } from 'react-router-dom';
import TrendCharts from "../charts/TrendCharts";
import DropdownMenu from '../DropdownMenu'
import TotalAlerts from './TotalAlerts';
import Trends from './Trends'
import LatestAlerts from './LatestAlerts'
import StatusPie from '../summary/StatusPie'

export default function Component(props){
    const [searchParams, setSearchParams] = useSearchParams();
    const [isExpanded,setisExpanded]=useState(true)
    const [position,setPosition]=useState({x:10,y:40})
    const [delta,setDelta]=useState({x:0,y:0})
    const [hoveringZoom, setHoveringZoom] = useState(false)
    const filterDate = (searchParams.get('date') || 'Today')
    const handleDrag=(e)=>{
        let newX=e.clientX - delta.x ;
        let newY=e.clientY - delta.y;
        setPosition({
            x:newX<0?0:newX>window.innerWidth-50?window.innerWidth-50:newX ,
            y:newY<0?0:newY>window.innerHeight-50?window.innerHeight-50:newY ,
        })
     }
    const handleDragStart=(e)=>{
        setDelta({x:e.clientX-position.x,y:e.clientY-position.y})
    }
    function handleParamChange(key, val){
        searchParams.delete(key)
        if(['from','to'].includes(key)) searchParams.delete('date')
        if(key === 'date'){
            searchParams.delete('from')
            searchParams.delete('to') 
        }
        if(key === 'clearDate') {
            searchParams.delete('from')
            searchParams.delete('to')
            searchParams.delete('date')
        }
        if(val !== '' && key !== 'clearDate') searchParams.set(key, val)
        setSearchParams(searchParams)
    }
    function handleDateChange(val){
        if(val !== 'Select Date') return handleParamChange('date', val)

    }
    
    return(
        <div onDragStart={handleDragStart}
            onDragEnd={handleDrag} draggable={true}
            className="absolute w-[560px] top-[90px] left-[20px] bg-black flex flex-col items-center text-white cursor-pointer" 
            style={{
                border:'1px solid #ffffff60',transition:'1s all ease',borderRadius:isExpanded?8:28,overflowY:'auto',maxHeight:'90%',
                top:position.y,left:position.x,
                background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(7, 28, 52, 0.85) 100%)',backdropFilter: 'blur(5px)',
            }}
        >
            <div className="w-full flex flex-row items-center px-[16px] py-[8px]">
                <svg width="1" height="20" viewBox="0 0 1 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.5" y1="2.39372e-08" x2="0.499999" y2="20" stroke="white"/>
                </svg>
                <svg className='ml-[4px]' width="3" height="12" viewBox="0 0 3 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="1.5" y1="-7.8056e-08" x2="1.5" y2="12" stroke="white" strokeWidth="3"/>
                </svg>

                <div className="flex-1 font-bold ml-[16px] text-xs" style={{}}>Quick Summary</div>
                {isExpanded&&<DropdownMenu options={['Today','7 Days','30 Days']} value={filterDate} select = {handleDateChange}/>}
                <div className='ml-2' onClick={()=>setisExpanded(!isExpanded)}>
                    {isExpanded?
                        <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.20703 9.39949L9.89941 0.707107L18.5918 9.39949H1.20703Z" stroke="white"/>
                        </svg>
                        :<svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.5919 1.39933L9.89954 10.0917L1.20715 1.39933L18.5919 1.39933Z" stroke="white"/>
                        </svg>
                    }
                </div>
            </div>

            {isExpanded&&<div className={"flex flex-col w-full items-center rounded-[8px] px-[16px] py-[8px]" + (hoveringZoom ? ' overflow-hidden' : ' overflow-auto')} style={{border:'1px solid #aaa'}}>
                <StatusPie isHome={true}/>
                <div className="mt-10"/>
                <div onMouseEnter={() => setHoveringZoom(true)} onMouseLeave={() => setHoveringZoom(false)}>
                    <TrendCharts isHome={true} />
                </div>
                <div className="mt-10"/>
                <LatestAlerts/>

            </div>}

        </div>
    )
}