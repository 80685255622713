import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect } from 'react-redux';
import { LEFTRIGHT_PADDING } from "../config/style.config";
import LoadingPage from "../components/Loading";
import NoPermission from './NoPermission';
import PageSelector from '../components/PageSelector';
import ListViewSvg from '../svgs/camera/ListView';
import GridViewSvg from '../svgs/camera/GridView';
import SmallSearchSvg from '../svgs/watchPage/SmallSearchSvg'
import TitleLeftSvg from '../svgs/TitleLeftSvg'
import EditIconSvg from "../svgs/camera/EditIcon";
import AlertPhotoSvg from '../svgs/camera/AlertPhotoSvg'
import AlertVideoSvg from '../svgs/camera/AlertVideoSvg'
import { fetchDangerZones } from "../services/dangerZones.service";
import BinSvg from '../svgs/camera/BinSvg'
import { Login } from "../actions/actions";
import { fetchAICameras } from "../services/cameras.service";
import WebrtcView from '../components/camera/WebrtcView';
import LatestSnapshot from '../components/camera/LatestSnapshot';
import LatestSnapshotPopup from '../components/camera/LatestSnapshotPopup';
import LatestVideoPopup from "../components/camera/LatestVideoPopup";


function Component(props){
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [isLoading,setisLoading] = useState(false)

    //search
    const searchTxt =  searchParams.get('search');
    const handleSearch=(e)=>{setSearchParams({page:1,search:e.target.value})}
    //send of search

    //paging
    const page =  parseInt(searchParams.get('page'))||1;
    const limit=2;
    const [total,setTotal]=useState(0)
    //const [activeTotal,setactiveTotal]=useState(0)
    const totalPage = Math.ceil(total/limit)||1
    const handleSelectPage=(p)=>async ()=>{
        if(p > totalPage || p < 1) return;
        searchParams.delete('page')
        searchParams.set('page', p)
        setSearchParams(searchParams)
    }
    //end of paging
    const [isGridView,setisGridView]=useState(true)
    const [data,setData]=useState([])
    const sortBy={'data.id':1};
    const fetchData=async(p=page,l=limit,st=sortBy)=>{
        const filter={ projectId:props.control?.projectId }
        if(searchTxt)filter['id']={"$regex":searchTxt, "$options":'i'}
        let result = await fetchDangerZones(props.token.token,filter,{ skip: l*(p-1), limit:l , sort:st})
        if(result.errno===10001){
            props.dispatch(Login(false))
            return alert('Session Expired');
        }else if(result?.result){

            for(let zone of result?.result){
                let camerasData = (await fetchAICameras(props.token?.token,{id:{$in:zone?.data?.cameras||[]}}))?.result||[]
                zone.camerasData=camerasData
            }

            setData(result?.result);
            setTotal(result?.count||0)
            //setactiveTotal(result?.activeCount||0)
        }
    }

    //snapshot popup
    const [isSnapshot,setisSnapshot]=useState(null)
    const handleShowPopup=(item,index)=>()=>{
        setisSnapshot(item)
    }
    const handleClosePopup=()=>{setisSnapshot(null)}
    //end of snapshot popup
     //video popup
     const [isVideoPopup,setisVideoPopup]=useState(null)
     const handleShowVideoPopup=(item,index)=>()=>{
         setisVideoPopup(item)
     }
     const handleCloseVideoPopup=()=>{setisVideoPopup(null)}
     //end of video popup

    useEffect(() => {
        const timer = setTimeout(() => {fetchData()}, 500)
        return () => clearTimeout(timer)
    },[searchParams])

    useEffect(()=>{
        searchParams.delete('page')
        searchParams.set('page', 1)
        setSearchParams(searchParams)
    },[props.control.projectId])

    if(!props.control?.permissions?.DZ_VIEW)
        return(<NoPermission/>)

    return(
        <div className="flex-col bg-[#0C0D14] flex-1 text-white text-[14px]">
            <div style={{...LEFTRIGHT_PADDING}} className='pb-64'>

                <div className="flex flex-row mt-[40px]">
                    <TitleLeftSvg className='mr-[9px]'/>
                    <div className="text-[24px] text-white font-bold ">Live view</div>
                </div>

                 {/*header/buttons/filter*/}
                 <div className="flex flex-row items-center justify-between mt-[27px]">
                    <div className="flex flex-row items-center">
                        <div className="subpage_selected">Danger Zone</div>
                        <div className="subpage_not_selected" onClick={() => navigate('/danger-zone/alert')}>Alert</div>
                    </div>
                    <div className="flex flex-row items-center gap-2 flex-1 justify-end"> 
                        <div onClick={()=>{setisGridView(true)}} className="flex flex-row items-center cursor-pointer gap-[4px] select-none" style={{color:isGridView?'#63BDFF':'white'}}>
                            <GridViewSvg color={isGridView?'#63BDFF':'white'}/>
                            Grid View
                        </div>
                        |
                        <div onClick={()=>{setisGridView(false)}} className="flex flex-row items-center cursor-pointer gap-[4px] select-none" style={{color:!isGridView?'#63BDFF':'white'}}>
                            <ListViewSvg color={!isGridView?'#63BDFF':'white'}/>
                            List View
                        </div>
                        <div className="rounded-full flex flex-row items-center justify-between max-w-[225px] px-[16px] py-[2px]" style={{border:'.5px solid #FFFFFF80'}}>
                            <SmallSearchSvg className='mr-[12px]' />
                            <input onChange={handleSearch} className="bg-transparent h-[21px] text-white min-w-0 " placeholder="Search Name"></input>
                        </div>
                        {/* <div className="button_blue" onClick={handleStartCreate}>
                            + Danger Zone
                        </div> */}
                    </div>
                </div>
                {/*end of header/buttons/filter*/}

                {data.map((zone,zoneIndex)=><div key={ zone?._id||zoneIndex} className='w-full flex flex-row gap-[24px] items-stretch mt-5 mb-10'>
                    <div className='flex flex-col justify-start' style={{flex:3}}>
                        <img src='/temp2.png' style={{objectFit:'contain',aspectRatio:1672/1512,height:undefined,width:'100%'}} alt='danger zone'/>
                    </div>

                    <div className=""style={{flex:6}}>
                        
                        {isGridView&&
                            <div className="flex-wrap	flex flex-row gap-[16px] items-center justify-stretch mb-[64px]">
                                {zone.camerasData.map((item,index) => 
                                    <div key={item?._id} className="inline-block py-[16px] px-[24px]" style={{border: '0.5px solid #FFFFFF80',width:'calc(50% - 8px)'}}>
                                        <div className="flex flex-row items-center justify-between">
                                            <div className="flex flex-row items-center text-white">
                                                <TitleLeftSvg className='mr-[9px]'/>
                                                {zone.id}/{item?.data?.name||'-'}  ({item?.id||'-'})
                                            </div>
                                        </div>
                                        <div className="flex flex-row items-center gap-2 text-white mt-[4px] text-[10px] text-[#ddd] font-[300]">
                                            {/* <CalenderSvg /> */}
                                            Last Update: {new Date(item?.modifyDate).toLocaleString()}
                                        </div>                   
                                        <div className="relative flex flex-row items-center gap-[16px] mt-[32px] w-full">
                                            <div className="flex-1 p-[8px]" style={{border:'.5px solid #ffffff80'}}>
                                                <LatestSnapshot deviceId={item?.id}/>
                                            </div>
                                            <div className="flex-1 p-[8px] relative flex items-center justify-center" style={{border:'.5px solid #ffffff80'}}>
                                                {   item?.webrtcValidTil>Date.now()&&item?.data?.isConnected?
                                                        <WebrtcView url={item?.webrtc}/>
                                                    : <div className="w-full relative flex flex-col items-center justify-center text-[10px] text-white" style={{aspectRatio:1920/1080}} >
                                                        Device offline
                                                    </div>
                                                }
                                            </div>
                                        </div>            
                                    </div>
                                )}
                            </div>
                        }
                        {/*main list*/}
                        {!isGridView&&
                            <div className="flex-wrap flex flex-row gap-[16px] items-center justify-stretch w-full mb-10" style={{borderBottom:'.5px solid #ffffff40'}}>
                                <div className="flex flex-row items-center text-white w-full pb-[24px]" style={{borderBottom:'.5px solid #ffffff40'}}>
                                    <TitleLeftSvg className='mr-[9px]'/>
                                    {zone.id}
                                </div>
                                <div className="py-[32px] w-full">
                                    <div className="flex flex-row text-[10px] font-bold w-full items-center px-[16px] mb-[16px]">
                                        <div className="flex-[1]">Item</div>
                                        <div className="flex-[2]">Camera Id</div>
                                        <div className="flex-[2]">Camera Name</div>
                                        <div className="flex-[2]">Last Update</div>
                                        <div className="flex-[2]">Latest Snapshot</div>
                                        <div className="flex-[2]">Lastest Alert Video</div>
                                        {/* <div className="w-[100px]"/> */}
                                    </div>
                                    {zone.camerasData.map((item, index) =>
                                        <div key={item?._id||index} className="text-[14px] flex flex-row py-[16px] px-[16px]" style={{overflowWrap: 'break-word',backgroundColor:index%2===1?'#FFFFFF0D':'transparent'}}>
                                            <div className="flex-[1]">{index+1}</div>
                                            <div className="flex-[2]">{item?.id||item?.data?.id}</div>
                                            <div className="flex-[2]">{item?.data?.name}</div>
                                            <div className="flex-[2]">{new Date(item?.modifyDate).toLocaleString()}</div>
                                            <div className="flex-[2] flex flex-row items-center gap-[4px] cursor-pointer" onClick={handleShowPopup(item,index)}>Snapshot <AlertPhotoSvg/></div>
                                            <div className="flex-[2] flex flex-row items-center gap-[4px] cursor-pointer" onClick={handleShowVideoPopup(item,index)}>Alert Video <AlertVideoSvg/></div>
                                            {/* <div className="w-[100px] flex flex-row items-center gap-[8px]">
                                                {props.control.permissions?.CAMERA_EDIT&&<div className='cursor-pointer bg-[#00202A] p-[8px] rounded-[7px]' onClick={handleStartUpdating(item,index)}><EditIconSvg/></div>}
                                                {props.control.permissions?.CAMERA_DELETE&&<div className="cursor-pointer" onClick={handleStartDeleteRecord(item,index)}><BinSvg className='cursor-pointer' color='#ffffff40'/></div>}
                                            </div> */}
                                        </div>
                                    )}
                                </div>
                                {/*end of data list*/}
                            </div>
                        }
                        {/*end of main list/grid*/}
                    </div>
                </div>
                )}
                <PageSelector
                    handleSelectPage={handleSelectPage}
                    page={page}
                    total={total}
                    totalPage={totalPage}
                />
            </div>


            {/*snapshot popup*/}
            {isSnapshot&&<LatestSnapshotPopup close={handleClosePopup} deviceId={isSnapshot?.id}/>}
            {isVideoPopup&&<LatestVideoPopup close={handleCloseVideoPopup} deviceId={isVideoPopup?.id}/>}
            {/*end of snapshot popup*/}
            {isLoading&&<LoadingPage/>}
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Component)


