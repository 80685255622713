import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect } from 'react-redux';
import { LEFTRIGHT_PADDING } from "../config/style.config";
import {Login} from '../actions/actions'
import TitleLeftSvg from '../svgs/TitleLeftSvg'
import LoadingPage from "../components/Loading";
import ToggleButton from '../components/ToggleButton'
import { createGasConfigRecord, editGasConfigRecord, fetchGasConfig } from "../services/confinedSpaces.service";

function Screen(props){
    const navigate = useNavigate();
    const [isLoading,setisLoading] = useState(false)

    
    const [gasConfig,setGasConfig]=useState({})
    const [gasConfig_id,setGasConfig_id]=useState(null)
    const fetchGasConfiguration=async()=>{
        const filter={ projectId:props.control?.projectId }
        let result = await fetchGasConfig(props.token.token,filter,{limit:1})
        if(result.errno===10001){
            props.dispatch(Login(false))
            return alert('Session Expired');
        }else if(result?.result){
            setGasConfig(result?.result?.[0]?.config||{})
            setGasConfig_id(result?.result?.[0]?._id||null)
        }
    }


    //setting data
    const [settingError,setsettingError]=useState('')
    const toggleByFieldId=(fieldId)=>()=>{
        setGasConfig({...gasConfig,[fieldId]:{
            ...( gasConfig?.[fieldId]||{}),
            enabled:(!gasConfig?.[fieldId]?.enabled)
        }})
    }
    const onInputChange=(fieldId,fieldId2)=>(e)=>{
        setGasConfig({...gasConfig,[fieldId]:{
            ...( gasConfig?.[fieldId]||{}),
            [fieldId2]:Number(e.target.value)||0
        }})
    }

    //end of setting data
    
    
    useEffect(()=>{
        fetchGasConfiguration()
    },[props.control.projectId])

    const handleApplySetting=async()=>{
        if(gasConfig_id){
            let res = await editGasConfigRecord(props.token?.token,gasConfig_id,{
                config:gasConfig
            })
            if(res.errno===10001){
                props.dispatch(Login(false))
                return alert('Session Expired');
            }else{
                alert('Updated');
                fetchGasConfiguration()
            }
        }else{
            let res = await createGasConfigRecord(props.token.token,{
                projectId:props.control.projectId,
                config:gasConfig
            })
            if(res.errno===10001){
                props.dispatch(Login(false))
                return alert('Session Expired');
            }else{
                alert('Updated');
                fetchGasConfiguration()
            }
        }
    }

    return(
        <div className="flex-col bg-[#0C0D14] flex-1 text-[14px] relative text-white  pb-[100px]" style={{...LEFTRIGHT_PADDING}}>
            <div className='flex flex-row w-full items-center justify-between mt-[40px]'>
                <div className="flex flex-row items-center">
                    <TitleLeftSvg className='mr-[9px]'/>
                    <div className="text-[24px] text-white font-bold">Confined Space Setting</div>
                </div>
                
                <div className="flex flex-row items-center  gap-[8px]">
                    <div className="button_white" onClick={()=>{navigate('/confined-space')}}>Cancel</div>
                    <div className="button_blue" style={{opacity:1}} onClick={handleApplySetting}>Apply</div>
                </div>
            </div>

            <div className="flex flex-row flex-wrap w-full mt-[40px] gap-[24px]">
                {/*left*/}
                <div className="flex flex-[4] flex-col p-[16px] gap-[12px] min-w-[520px] max-w-[100%]" style={{border:'.5px solid #ffffff80'}}>
                    <div className="flex flex-row mb-[24px]">
                        <TitleLeftSvg className='mr-[9px]' width={12} />
                        <div className="text-[16px] text-white font-bold">Setting</div>
                    </div>

                   
                    <div className="flex flex-row items-center gap-[12px]">
                        <ToggleButton value={!!gasConfig?.gas_o2?.enabled} onClick={toggleByFieldId('gas_o2')}/>
                        <div>Enable Oxygen Alert</div>
                        {/* <input onChange={onInputChange('bloodOxygenLowerLimit')} min={1} max={100} value={setting?.bloodOxygenLowerLimit||''} className='w-[60px] h-full rounded-full bg-[transparent] px-[10px]'style={{border:'.5px solid #ffffff80'}}/> */}
                        {/* <div>%</div> */}
                    </div>
                    {gasConfig?.gas_o2?.enabled&&<div className="flex flex-row items-center gap-[12px]">
                        Warning threshold:
                        From <input onChange={onInputChange('gas_o2','warning_lower')} type="number" min={1} max={100} value={gasConfig?.gas_o2?.warning_lower||''} className='w-[80px] h-full rounded-full bg-[transparent] px-[10px]'style={{border:'.5px solid #ffffff80'}}/>
                        <div>%</div>
                        to <input onChange={onInputChange('gas_o2','warning_upper')} type="number" min={1} max={100} value={gasConfig?.gas_o2?.warning_upper||''} className='w-[80px] h-full rounded-full bg-[transparent] px-[10px]'style={{border:'.5px solid #ffffff80'}}/>
                        <div>%</div>
                    </div>}
                    {gasConfig?.gas_o2?.enabled&&<div className="flex flex-row items-center gap-[12px]">
                        Evacuate threshold:
                        From <input onChange={onInputChange('gas_o2','evacuate_lower')} type="number" min={1} max={100} value={gasConfig?.gas_o2?.evacuate_lower||''} className='w-[80px] h-full rounded-full bg-[transparent] px-[10px]'style={{border:'.5px solid #ffffff80'}}/>
                        <div>%</div>
                        to <input onChange={onInputChange('gas_o2','evacuate_upper')} type="number" min={1} max={100} value={gasConfig?.gas_o2?.evacuate_upper||''} className='w-[80px] h-full rounded-full bg-[transparent] px-[10px]'style={{border:'.5px solid #ffffff80'}}/>
                        <div>%</div>
                    </div>}



                    <div className="flex flex-row items-center gap-[12px]">
                        <ToggleButton value={!!gasConfig?.temperature?.enabled} onClick={toggleByFieldId('temperature')}/>
                        <div>Enable Temperature Alert</div>
                    </div>
                    {gasConfig?.temperature?.enabled&&<div className="flex flex-row items-center gap-[12px]">
                        Warning threshold:
                        <input onChange={onInputChange('temperature','warning')} type="number" min={10} max={100} value={gasConfig?.temperature?.warning||''} className='w-[80px] h-full rounded-full bg-[transparent] px-[10px]'style={{border:'.5px solid #ffffff80'}}/>
                        <div>°C</div>
                    </div>}
                    {gasConfig?.temperature?.enabled&&<div className="flex flex-row items-center gap-[12px]">
                        Evacuate threshold:
                        <input onChange={onInputChange('temperature','evacuate')} type="number" min={10} max={100} value={gasConfig?.temperature?.evacuate||''} className='w-[80px] h-full rounded-full bg-[transparent] px-[10px]'style={{border:'.5px solid #ffffff80'}}/>
                        <div>°C</div>
                    </div>}


                    <div className="flex flex-row items-center gap-[12px]">
                        <ToggleButton value={!!gasConfig?.particle_pm25?.enabled} onClick={toggleByFieldId('particle_pm25')}/>
                        <div>Enable PM2.5 Alert</div>
                    </div>
                    {gasConfig?.particle_pm25?.enabled&&<div className="flex flex-row items-center gap-[12px]">
                        Warning threshold:
                        <input onChange={onInputChange('particle_pm25','warning')} type="number" min={1} max={100} value={gasConfig?.particle_pm25?.warning||''} className='w-[80px] h-full rounded-full bg-[transparent] px-[10px]'style={{border:'.5px solid #ffffff80'}}/>
                        <div>ug/m3</div>
                    </div>}
                    {gasConfig?.particle_pm25?.enabled&&<div className="flex flex-row items-center gap-[12px]">
                        Evacuate threshold:
                        <input onChange={onInputChange('particle_pm25','evacuate')} type="number" min={1} max={100} value={gasConfig?.particle_pm25?.evacuate||''} className='w-[80px] h-full rounded-full bg-[transparent] px-[10px]'style={{border:'.5px solid #ffffff80'}}/>
                        <div>ug/m3</div>
                    </div>}


                    <div className="flex flex-row items-center gap-[12px]">
                        <ToggleButton value={!!gasConfig?.gas_co?.enabled} onClick={toggleByFieldId('gas_co')}/>
                        <div>Enable CO Alert</div>
                    </div>
                    {gasConfig?.gas_co?.enabled&&<div className="flex flex-row items-center gap-[12px]">
                        Warning threshold:
                        <input onChange={onInputChange('gas_co','warning')} type="number" min={1} max={100} value={gasConfig?.gas_co?.warning||''} className='w-[80px] h-full rounded-full bg-[transparent] px-[10px]'style={{border:'.5px solid #ffffff80'}}/>
                        <div>PPB</div>
                    </div>}
                    {gasConfig?.gas_co?.enabled&&<div className="flex flex-row items-center gap-[12px]">
                        Evacuate threshold:
                        <input onChange={onInputChange('gas_co','evacuate')} type="number" min={1} max={100} value={gasConfig?.gas_co?.evacuate||''} className='w-[80px] h-full rounded-full bg-[transparent] px-[10px]'style={{border:'.5px solid #ffffff80'}}/>
                        <div>PPB</div>
                    </div>}




                    <div className="flex flex-row items-center gap-[12px]">
                        <ToggleButton value={!!gasConfig?.gas_co2?.enabled} onClick={toggleByFieldId('gas_co2')}/>
                        <div>Enable CO2 Alert</div>
                    </div>
                    {gasConfig?.gas_co2?.enabled&&<div className="flex flex-row items-center gap-[12px]">
                        Warning threshold:
                        <input onChange={onInputChange('gas_co2','warning')} type="number" min={1} max={10000} value={gasConfig?.gas_co2?.warning||''} className='w-[80px] h-full rounded-full bg-[transparent] px-[10px]'style={{border:'.5px solid #ffffff80'}}/>
                        <div>PPM</div>
                    </div>}
                    {gasConfig?.gas_co2?.enabled&&<div className="flex flex-row items-center gap-[12px]">
                        Evacuate threshold:
                        <input onChange={onInputChange('gas_co2','evacuate')} type="number" min={1} max={10000} value={gasConfig?.gas_co2?.evacuate||''} className='w-[80px] h-full rounded-full bg-[transparent] px-[10px]'style={{border:'.5px solid #ffffff80'}}/>
                        <div>PPM</div>
                    </div>}

                    <div className="flex flex-row items-center gap-[12px]">
                        <ToggleButton value={!!gasConfig?.gas_h2s?.enabled} onClick={toggleByFieldId('gas_h2s')}/>
                        <div>Enable H2S Alert</div>
                    </div>
                    {gasConfig?.gas_h2s?.enabled&&<div className="flex flex-row items-center gap-[12px]">
                        Warning threshold:
                        <input onChange={onInputChange('gas_h2s','warning')} type="number" min={1} max={20000} value={gasConfig?.gas_h2s?.warning||''} className='w-[80px] h-full rounded-full bg-[transparent] px-[10px]'style={{border:'.5px solid #ffffff80'}}/>
                        <div>PPB</div>
                    </div>}
                    {gasConfig?.gas_h2s?.enabled&&<div className="flex flex-row items-center gap-[12px]">
                        Evacuate threshold:
                        <input onChange={onInputChange('gas_h2s','evacuate')} type="number" min={1} max={20000} value={gasConfig?.gas_h2s?.evacuate||''} className='w-[80px] h-full rounded-full bg-[transparent] px-[10px]'style={{border:'.5px solid #ffffff80'}}/>
                        <div>PPB</div>
                    </div>}


                    <div className="flex flex-row items-center gap-[12px]">
                        <ToggleButton value={!!gasConfig?.gas_ch4?.enabled} onClick={toggleByFieldId('gas_ch4')}/>
                        <div>Enable CH4 Alert</div>
                    </div>
                    {gasConfig?.gas_ch4?.enabled&&<div className="flex flex-row items-center gap-[12px]">
                        Warning threshold:
                        <input onChange={onInputChange('gas_ch4','warning')} type="number" min={1} max={10000} value={gasConfig?.gas_ch4?.warning||''} className='w-[80px] h-full rounded-full bg-[transparent] px-[10px]'style={{border:'.5px solid #ffffff80'}}/>
                        <div>PPM</div>
                    </div>}
                    {gasConfig?.gas_ch4?.enabled&&<div className="flex flex-row items-center gap-[12px]">
                        Evacuate threshold:
                        <input onChange={onInputChange('gas_ch4','evacuate')} type="number" min={1} max={10000} value={gasConfig?.gas_ch4?.evacuate||''} className='w-[80px] h-full rounded-full bg-[transparent] px-[10px]'style={{border:'.5px solid #ffffff80'}}/>
                        <div>PPM</div>
                    </div>}


                    <div className="flex flex-row items-center gap-[12px]">
                        <ToggleButton value={!!gasConfig?.gas_ex?.enabled} onClick={toggleByFieldId('gas_ex')}/>
                        <div>Enable Compostable Gas Alert</div>
                    </div>
                    {gasConfig?.gas_ex?.enabled&&<div className="flex flex-row items-center gap-[12px]">
                        Warning threshold:
                        <input onChange={onInputChange('gas_ex','warning')} type="number" min={1} max={100} value={gasConfig?.gas_ex?.warning||''} className='w-[80px] h-full rounded-full bg-[transparent] px-[10px]'style={{border:'.5px solid #ffffff80'}}/>
                        <div>% LEL</div>
                    </div>}
                    {gasConfig?.gas_ex?.enabled&&<div className="flex flex-row items-center gap-[12px]">
                        Evacuate threshold:
                        <input onChange={onInputChange('gas_ex','evacuate')} type="number" min={1} max={100} value={gasConfig?.gas_ex?.evacuate||''} className='w-[80px] h-full rounded-full bg-[transparent] px-[10px]'style={{border:'.5px solid #ffffff80'}}/>
                        <div>% LEL</div>
                    </div>}


                    <div className="text-[red] text-[10px]">{settingError}</div>
                </div>
                {/*end of left*/}


            </div>

            


            {isLoading&&<LoadingPage/>}
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Screen)