import { DEFAULT_PROJECT_ID } from "../config";
import { LANGUAGES } from "../dto/language";

export default function reducer(state={
	isloading:false,
	isInit:false,
	
	page_index:'',
	language:LANGUAGES.EN,
	projectId: DEFAULT_PROJECT_ID,
	cameraPopup:null,
	sethomepagePopup:null,
	permissions:{},
	userData:{}
},action) {
	//const newState = {...state}
	switch(action.type){
		case "setloading":{
			state={...state, 
				isloading:action.payload
			}
			break;
		}
		case "setisinit":{
			state={...state, 
				isInit:action.payload
			}
			break;
		}
		case "Login":{
			state={...state, 
				page_index:''
			}
			break;
		}
		case "setpageindex":{
			state={...state, 
				page_index:action.payload,
			}
			break;
		}
		case "setlanguage":{
			state={...state, 
				language:action.payload,
			}
			break;
		}

		//todo delete this later, useless now
		case "setcameraPopup":{
			state={...state, 
				cameraPopup:action.payload,
			}
			break;
		}
		//end of todo

		
		case "sethomepagePopup":{
			state={...state, 
				homepagePopup:action.payload,
			}
			break;
		}
		case "setprojectId":{
			state={...state, 
				projectId:action.payload,
			}
			break;
		}
		case "setpermissions":{
			state={...state, 
				permissions:action.payload,
			}
			break;
		}
		case "setuserData":{
			state={...state, 
				userData:action.payload,
			}
			break;
		}
		case "ERR":{
			throw new Error("Reducer Error")
		}
		default: {
			;
		}
	}

	return state
}