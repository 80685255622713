import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect } from 'react-redux';
import { LEFTRIGHT_PADDING } from "../config/style.config";
import DropDown from '../components/DropdownMenu'
import GasChart from '../components/confinedSpace/GasChart';
//svg
import TitleLeftSvg from '../svgs/TitleLeftSvg'
import { fetchConfinedSpace, fetchGasConfig, fetchGasData } from "../services/confinedSpaces.service";
import { Login } from "../actions/actions";
//

function Component(props){
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [isLoading,setisLoading] = useState(false)

    const [devices,setDevices]=useState([])
    const fetchConfinedSpaceList=async(p=1,l=99,st={'data.name':1})=>{
        const filter={ projectId:props.control?.projectId }
        let result = await fetchConfinedSpace(props.token.token,filter,{ skip: l*(p-1), limit:l , sort:st})
        if(result.errno===10001){
            props.dispatch(Login(false))
            return alert('Session Expired');
        }else if(result?.result){
            setDevices(result?.result);
        }
    }

    const selectedDeviceName= searchParams.get('name')|| devices?.[0]?.data?.name;
    const time= searchParams.get('time')||`${new Date().getHours()<10?'0':''}${new Date().getHours()}:${new Date().getMinutes()<10?'0':''}${new Date().getMinutes()}`
    const date = searchParams.get('date')|| new Date(new Date().getTime()+8*60000*60).toISOString().split('T')?.[0]

    const handleSelectDevice=(item,index)=>{
        searchParams.delete('name')
        searchParams.set('name', item)
        setSearchParams(searchParams)
    }
    const handleSelectTime=(item,index)=>{
        searchParams.delete('time')
        searchParams.set('time', item)
        setSearchParams(searchParams)
    }
    const [isShowDateSelection,setisShowDateSelection]=useState(false)
    const handleSelectDate=(item,index)=>{
        let now=new Date()
        if(item==='Today'){
            let newDate= new Date(now.getTime()+8*60000*60).toISOString().split('T')?.[0]
            searchParams.delete('date')
            searchParams.set('date', newDate)
            setSearchParams(searchParams)
        }else if(item==='Yesterday'){
            let newDate= new Date(now.getTime()-16*60000*60).toISOString().split('T')?.[0]
            searchParams.delete('date')
            searchParams.set('date', newDate)
            setSearchParams(searchParams)
        }else{
            setisShowDateSelection(true)
        }
        //if()
    }

    const [gasConfig,setGasConfig]=useState({})
    const fetchGasConfiguration=async()=>{
        const filter={ projectId:props.control?.projectId }
        let result = await fetchGasConfig(props.token.token,filter,{limit:1})
        if(result.errno===10001){
            props.dispatch(Login(false))
            return alert('Session Expired');
        }else if(result?.result){
            setGasConfig(result?.result?.[0]?.config||{})
        }
    }

    const [data,setData]= useState([])
    const fetchData=async()=>{
        const d= new Date(`${date} ${time}`)
        const filter={
            'data.sn':devices.filter(d=>d.data?.name===selectedDeviceName)?.[0]?.id,
            timestamp:{$gte:d.toISOString(),$lt: new Date(d.getTime()+1000*60*30)}
        }
        let result = await fetchGasData(props.token?.token,filter,{sort:{timestamp:1},limit:999})
        if(result.errno===10001){
            props.dispatch(Login(false))
            return alert('Session Expired');
        }else if(result?.result){
            setData(result?.result||[])
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {fetchData()}, 500)
        return () => clearTimeout(timer)
    },[searchParams])

    useEffect(()=>{
        searchParams.set('name', '')
        setSearchParams(searchParams)
        fetchConfinedSpaceList()
        fetchGasConfiguration()
    },[props.control.projectId])

    return(
        <div className="flex-col bg-[#0C0D14] text-white flex-1 text-[14px]">
            <div style={{...LEFTRIGHT_PADDING}} className='pb-[24px]'>
                <div className="flex flex-row my-[40px] items-center justify-between">
                    <div className="flex flex-row items-center">
                        <TitleLeftSvg className='mr-[9px]'/>
                        <div className="text-[24px] text-white font-bold ">Confined Space</div>
                    </div>
                </div>

                <div className="flex flex-row justify-between items-center text-white">
                    <div className="flex flex-row mt-[27px]">
                        <div className="subpage_not_selected" onClick={() => navigate('/confined-space')}>Confined Space</div>
                        <div className="subpage_selected" onClick={() => navigate('/confined-space/history')}>Historical Data</div>
                        <div className="subpage_not_selected" onClick={() => navigate('/confined-space/alert')}>Alert</div>
                    </div>
                    <div className='flex flex-row items-center gap-[4px]'>
                        <DropDown value={selectedDeviceName||'-'} options={devices.map(d=>d?.data?.name||'-')} select={handleSelectDevice}/>
                        <DropDown value={date} options={['Today','Yesterday','Select Day']} select={handleSelectDate}/>
                        <DropDown value={time} options={[
                            '00:00','00:30','01:00','01:30','02:00','02:30','03:00','03:30','04:00','04:30',
                            '05:00','05:30','06:00','06:30','07:00','07:30','08:00','08:30','09:00','09:30',
                            '10:00','10:30','11:00','11:30','12:00','12:30','13:00','13:30','14:00','14:30',
                            '15:00','15:30','16:00','16:30','17:00','17:30','18:00','18:30','19:00','19:30',
                            '20:00','20:30','21:00','21:30','22:00','22:30','23:00','23:30'
                        ]} select={handleSelectTime}/>
                    </div>
                </div>
            </div>

            <div className="w-full flex flex-col " style={{...LEFTRIGHT_PADDING}}>
                <GasChart label='O2' gasConfig={gasConfig} data={data} field='gas_o2'  time={new Date(`${date} ${time}`)}/>
                <GasChart label='Temperature' gasConfig={gasConfig} data={data} field='temperature'  time={new Date(`${date} ${time}`)}/>
                <GasChart label='PM2.5' gasConfig={gasConfig} data={data} field='particle_pm25'  time={new Date(`${date} ${time}`)}/>
                <GasChart label='CO' gasConfig={gasConfig} data={data} field='gas_co'  time={new Date(`${date} ${time}`)}/>
                <GasChart label='CO2' gasConfig={gasConfig} data={data} field='gas_co2'  time={new Date(`${date} ${time}`)}/>
                <GasChart label='H2S' gasConfig={gasConfig} data={data} field='gas_h2s'  time={new Date(`${date} ${time}`)}/>
                <GasChart label='CH4' gasConfig={gasConfig} data={data} field='gas_ch4'  time={new Date(`${date} ${time}`)}/>
                <GasChart label='Compostable Gas' gasConfig={gasConfig} data={data} field='gas_ex'  time={new Date(`${date} ${time}`)}/>
            </div>


            {isShowDateSelection && <div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)', zIndex:99}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[350px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                    <div className='flex flex-row items-center space-between w-full'>
                        <div className='font-bold'>Select a date</div>          
                    </div>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={() => {setisShowDateSelection (false)}}>X</div>         
                    <div className='flex flex-row my-4 w-full justify-around'>
                        <div className='w-[50px]'>From:</div>   
                        <input type='date' defaultValue={date} onChange={(e) => {
                           searchParams.delete('date')
                           searchParams.set('date', e.target.value)
                           setSearchParams(searchParams)
                           setisShowDateSelection(false)
                        }} className='border rounded-full px-2  min-w-[180px]'style={{colorScheme: 'dark'}}></input>    
                    </div>
                </div>
            </div>}
        </div>
    )
}


export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Component)

