import React, { useEffect, useState, useRef } from "react";
import { useNavigate,useSearchParams } from "react-router-dom";
import { connect } from 'react-redux';
import { LEFTRIGHT_PADDING } from "../config/style.config";
//
import ConfinedSpaceChart from '../components/charts/ConfinedSpaceChart'
import DropDown from '../components/DropdownMenu';
import ConfinedSpaceAlertList from "../components/confinedSpace/ConfinedSpaceAlertList";
import PageSelector from "../components/PageSelector"
//svg
import TitleLeftSvg from '../svgs/TitleLeftSvg'
import ChartChainLink from '../svgs/ChartChainLink'
import SmallSearchSvg from '../svgs/watchPage/SmallSearchSvg'
import CopyLink from "../components/CopyLink";
import { handleFilterDate, yearMonthDay } from "../actions/actionsDateTime"; 
import { fetchConfinedSpacesEvent } from "../services/confinedSpaces.service";
const limit = 10
const TypeParamToValue = {
    "O2" : {$in:['CS_O2_EXCEED_WARN','CS_O2_EXCEED_EVACUATE']},
    "Temperature":{$in:['CS_TEMPERATURE_WARN','CS_O2_EXCEED_EVACUATE']},
    "PM25" : {$in:['CS_PM25_EXCEED_WARN','CS_PM25_EXCEED_EVACUATE']},
    "CO":{$in:['CS_CO_EXCEED_WARN','CS_CO_EXCEED_EVACUATE']},
    "CO2":{$in:['CS_CO2_EXCEED_WARN','CS_CO2_EXCEED_EVACUATE']},
    "H2S":{$in:['CS_H2S_EXCEED_WARN','CS_H2S_EXCEED_EVACUATE']},
    "CH4":{$in:['CS_CH4_EXCEED_WARN','CS_CH4_EXCEED_EVACUATE']},
    "Compostable Gas":{$in:['CS_COM_GAS_EXCEED_WARN','CS_COM_GAS_EXCEED_EVACUATE']},
}


function Component(props){
    const fromRef = useRef(null)
    const toRef = useRef(null)
    const [searchParams, setSearchParams] = useSearchParams();
    const [totalItem, setTotalItem] = useState(1)
    const [popType, setPopType] = useState(undefined)
    const totalPage = Math.ceil(totalItem/limit)||1
    const page =  parseInt(searchParams.get('page'))||1
    const filterType = searchParams.get('type')
    const filterName = searchParams.get('search')
    const filterDate = (searchParams.get('date') || 'Today')
    const filterFrom = searchParams.get('from')
    const filterTo = searchParams.get('to')
    const navigate = useNavigate();
    const [ChartData, setChartData] = useState([])
    const [isHoverChart,setisHoverChart]= useState(false)
    //
    useEffect(() => {
        const timer = setTimeout(() => fetchData(), 500)
        return() => clearTimeout(timer)
    },[searchParams])
    async function fetchData(){
        // formatDataForChart()
        const filter = {}
        if(filterType) filter['type'] = TypeParamToValue[filterType]
        if(filterName) filter['$or'] = [{name: {"$regex": filterName}},{deviceID: {"$regex": filterName, "$options":'i'}}]
        if(filterDate) filter['time'] = handleFilterDate(filterDate)
        if(filterFrom && filterTo) filter['time'] = handleFilterDate('', filterFrom, filterTo)
        filter["projectId"] = props.control.projectId
        const res = await fetchConfinedSpacesEvent(props.token.token, filter, {limit,skip:limit*(page-1)})
        if(res.errno) return
        setTotalItem(res.count)
        setChartData(res.result)

        const toPage = Math.ceil(res.count/limit) || 1
        if(page > toPage) handleParamChange('page', 1)
    }
    const handleSelectPage=(num)=>()=>{
        handleParamChange('page', num)
    }
    function handleParamType(item){
        searchParams.delete('type')
        if(item !== 'All')searchParams.set('type', item)
        searchParams.delete('page')
        setSearchParams(searchParams)
    }
    function handleParamChange(key, val){
        searchParams.delete(key)
        if(key === 'clearDate') {
            searchParams.delete('from')
            searchParams.delete('to')
            searchParams.delete('date')
            fromRef.current.value = ""
            toRef.current.value = ""
        }
        if(['from','to'].includes(key)) searchParams.delete('date')
        if(key === 'date'){
            searchParams.delete('from')
            searchParams.delete('to') 
        }
        if(key !== 'page'){
            searchParams.delete('page')
        }
        if(val !== '' && key !== 'clearDate') searchParams.set(key, val)
        setSearchParams(searchParams)
    }
    function handleDateChange(val){
        if(val !== 'Select Date') return handleParamChange('date', val)
        setPopType('date')

    }
    return(
        <div className={"flex-col bg-[#0C0D14] flex-1 text-[14px] h-full"  + (isHoverChart ? ' overflow-hidden' : ' overflow-auto')}>
            {popType === 'date' && <div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)', zIndex:99}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[350px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                    <div className='flex flex-row items-center space-between w-full'>
                        <div className='font-bold'>Select a date range*</div>          
                    </div>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={() => {setPopType (false)}}>X</div>         
                    <div className='flex flex-row my-4 w-full justify-around'>
                        <div className='w-[50px]'>From:</div>   
                        <input type='date' defaultValue={filterFrom} max={filterTo || yearMonthDay(new Date())} onChange={(e) => {handleParamChange('from', e.target.value)}} className='border rounded-full px-2  min-w-[180px]'style={{colorScheme: 'dark'}}></input>    
                    </div>
                    <div className='flex flex-row mb-4 w-full justify-around'>
                        <div className='w-[50px]'>To:</div>   
                        <input type='date' defaultValue={filterTo} min={filterFrom || undefined} onChange={(e) => {handleParamChange('to', e.target.value)}} className='border rounded-full px-2  min-w-[180px]'style={{colorScheme: 'dark'}}></input>    
                    </div>
                    <div className='w-full flex flex-row items-center justify-between gap-2'>
                        <div className='button_blue w-[50%]' onClick={() => {setPopType(false)}}>Confirm</div>
                        <div className='button_blue w-[50%] text-white' onClick={() => {handleParamChange('clearDate')}}>Clear</div>
                    </div>
                </div>
            </div>}
            <div style={{...LEFTRIGHT_PADDING}} className='pb-[24px]'>
                <div className="flex flex-row my-[40px] items-center justify-between">
                    <div className="flex flex-row items-center">
                        <TitleLeftSvg className='mr-[9px]'/>
                        <div className="text-[24px] text-white font-bold ">Confined Space</div>
                    </div>
                </div>
                <div className="flex flex-row justify-between items-center text-white">
                    <div className="flex flex-row mt-[27px]">
                        <div className="subpage_not_selected" onClick={() => navigate('/confined-space')}>Confined Space</div>
                        <div className="subpage_not_selected" onClick={() => navigate('/confined-space/history')}>Historical Data</div>
                        <div className="subpage_selected" onClick={() => navigate('/confined-space/alert')}>Alert</div>
                    </div>
                    <DropDown value={(filterFrom && filterTo ? `${filterFrom} / ${filterTo}` :filterDate)} select = {handleDateChange}/>
                </div>


                {/*chart*/}
                <div className="flex flex-col w-full mt-[25px] text-[14px]" style={{border: '1px solid #FFFFFF80'}}>
                    <div className="flex flex-row items-center justify-between py-[24px] px-[16px]">
                        <div className="text-white text-[18px] font-bold flex items-center gap-2">
                            {/* <TitleLeftSvg className='mr-[9px]'/>  */}
                            Confined Space Alert <CopyLink/>
                        </div>
                    </div>
                    <div className="px-[26px] pt-[13px] w-full" onMouseEnter={()=>setisHoverChart(true)} onMouseLeave={()=>{setisHoverChart(false)}}>
                        <ConfinedSpaceChart/>
                    </div>
                </div>
                {/*chart*/}

                {/*event list*/}
                <div className="flex flex-col mt-[48px] w-full text-[14px] mb-10 text-white" style={{border: '1px solid #FFFFFF80'}}>
                    <div className="px-[24px] pt-[13px] flex flex-row items-center justify-between">
                        <DropDown options={['All','O2','Temperature','PM2.5','CO','CO2','H2S','CH4','Compostable Gas']} value={filterType || 'Type'} select={handleParamType}/>
                        <div className="flex flex-row gap-2">
                            <div className="rounded-full flex flex-row items-center justify-between max-w-[225px] px-[16px] py-[2px]" style={{borderColor:'#FFFFFF80', borderWidth:1}}>
                                <SmallSearchSvg className='mr-[12px]' />
                                <input defaultValue={filterName || ''} onChange={(e) => handleParamChange('search',e.target.value)} className="bg-transparent h-[21px] text-white min-w-0 outline-none" placeholder="Serial No. or Name"></input>
                            </div>
                        </div>
                    </div>
                    <ConfinedSpaceAlertList alertArr={ChartData} fetchData={fetchData}/>
                </div>
                <PageSelector page={page} total={totalItem} totalPage={totalPage} handleSelectPage={handleSelectPage}/>
            </div>
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Component)