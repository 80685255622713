import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect } from 'react-redux';
import { LEFTRIGHT_PADDING } from "../config/style.config";
import DropDown from '../components/DropdownMenu'
import PageSelector from '../components/PageSelector'
import {createConfinedSpacesRecord, deleteConfinedSpacesRecord, editConfinedSpacesRecord, fetchConfinedSpace, fetchGasConfig} from '../services/confinedSpaces.service';
import NoPermission from './NoPermission'
//svg
import TitleLeftSvg from '../svgs/TitleLeftSvg';
import EditIconSvg from "../svgs/camera/EditIcon";
import BinSvg from '../svgs/camera/BinSvg';
import DeviceSettingSvg from '../svgs/watchPage/DeviceSettingSvg';
import LoadingPage from "../components/Loading";
import { Login } from "../actions/actions";
//
function Component(props){
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [isLoading,setisLoading] = useState(false)

    const [filterState, setFilterState] = useState('1 Min')

    //search
    const searchTxt =  searchParams.get('search');
    const handleSearch=(e)=>{setSearchParams({page:1,search:e.target.value})}
    //send of search

    //paging
    const page =  parseInt(searchParams.get('page'))||1;
    const limit=10;
    const [total,setTotal]=useState(0)
    //const [activeTotal,setactiveTotal]=useState(0)
    const totalPage = Math.ceil(total/limit)||1
    const handleSelectPage=(p)=>async ()=>{
        if(p > totalPage || p < 1) return;
        searchParams.delete('page')
        searchParams.set('page', p)
        setSearchParams(searchParams)
    }
    //end of paging

    const [data,setData]=useState([])
    const sortBy={'data.id':1};
    const fetchData=async(p=page,l=limit,st=sortBy)=>{
        const filter={ projectId:props.control?.projectId }
        if(searchTxt)filter['id']={"$regex":searchTxt, "$options":'i'}
        let result = await fetchConfinedSpace(props.token.token,filter,{ skip: l*(p-1), limit:l , sort:st})
        if(result.errno===10001){
            props.dispatch(Login(false))
            return alert('Session Expired');
        }else if(result?.result){
            setData(result?.result);
            setTotal(result?.count||0)
            //setactiveTotal(result?.activeCount||0)
        }
    }
    const [gasConfig,setGasConfig]=useState({})
    const fetchGasConfiguration=async()=>{
        const filter={ projectId:props.control?.projectId }
        let result = await fetchGasConfig(props.token.token,filter,{limit:1})
        if(result.errno===10001){
            props.dispatch(Login(false))
            return alert('Session Expired');
        }else if(result?.result){
            setGasConfig(result?.result?.[0]?.config||{})
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {fetchData()}, 500)
        return () => clearTimeout(timer)
    },[searchParams])

    useEffect(()=>{
        searchParams.delete('page')
        searchParams.set('page', 1)
        setSearchParams(searchParams)
        fetchGasConfiguration()
    },[props.control.projectId])




    //edit record
    const [isUpdating,setisUpdating]=useState(false)
    const [updateError,setupdateError]=useState(null)
    const handleStartUpdating =(item,index)=>()=>{setisUpdating(JSON.parse(JSON.stringify(item)))}
    const handleCancelUpdate=()=>{setisUpdating(null);setupdateError(null)}
    const handleEditRecordInput=(field)=>(e)=>{
        let d= {...isUpdating}
        d.data[field]=e.target.value
        if(field==='id')d.id=e.target.value
        setisUpdating(d)
    }
    const handleUpdate=async()=>{
        if(!isUpdating?.id){setupdateError('Serial Number cannot be empty.');return}
        if(!isUpdating?.data?.name){setupdateError('Device Name cannot be empty.');return}
        if(isLoading)return
        setisLoading(true)
        let updateData = {
            id: isUpdating?.id,
            'data.id':isUpdating?.id,
            'data.name':isUpdating?.data?.name,
            'data.longitude': isUpdating?.data?.longitude? Number(isUpdating?.data?.longitude): null,
            'data.latitude': isUpdating?.data?.latitude? Number(isUpdating?.data?.latitude): null
        }
        let result = await editConfinedSpacesRecord(props.token?.token,isUpdating?._id,updateData)
        if(result.errno){
            setupdateError(`Error: ${JSON.stringify(result?.message)}`)
        }else{
            handleCancelUpdate()
            fetchData()
        }
        setisLoading(false);
    }
    //end of edit record

    //new record
    const [isCreating,setisCreating]=useState(false)
    const [createError,setCreateError]=useState(null)
    const handleStartCreate =()=>{setisCreating({data:{}})}
    const handleCancelCreate=()=>{setisCreating(null);setCreateError(null)}
    const handleCreateRecordInput=(field)=>(e)=>{
        let d= {...isCreating}
        d.data[field]=e.target.value
        if(field==='id')d.id=e.target.value
        setisCreating(d)
    }
    const handleCreateRecord=async()=>{
        if(!isCreating?.id){setCreateError('Serial Number cannot be empty.');return}
        if(!isCreating?.data?.name){setCreateError('Device Name cannot be empty.');return}
        if(isLoading)return
        setisLoading(true)
        let updateData = {
            id: isCreating?.id,
            projectId: props.control?.projectId,
            data:{
                id:isCreating?.id,
                name:isCreating?.data?.name,
                longitude: isCreating?.data?.longitude? Number(isCreating?.data?.longitude): null,
                latitude: isCreating?.data?.latitude? Number(isCreating?.data?.latitude): null
            }
        }
        let result = await createConfinedSpacesRecord(props.token?.token,updateData)
        if(result.errno){
            setCreateError(`Error: ${JSON.stringify(result?.message)}`)
        }else{
            handleCancelCreate()
            fetchData()
        }
        setisLoading(false);
    }
    //end of new record

    //delete params
    const [deleteRecord,setdeleteRecord]=useState(null)
    const [deleteRecordError,setdeleteRecordError]=useState(null)
    const handleCancelDeleteRecord=()=>{setdeleteRecord(null)}
    const handleStartDeleteRecord=(item,index)=>()=>{ setdeleteRecord(item) }
    const handleDeleteRecord=async()=>{
        if(isLoading)return
        setisLoading(true)
        let result = await deleteConfinedSpacesRecord(props.token?.token,deleteRecord?._id);
        if(result.errno){
            setdeleteRecordError(`Error: ${JSON.stringify(result?.message)}`)
        }else{
            handleCancelDeleteRecord()
            fetchData()
        }
        setisLoading(false);
    }
    //end of delete params

    useEffect(()=>{
        const timeInterval= setInterval(()=>{fetchData()},
            filterState==='1 Min'
            ? 1000*60
            : filterState==='5 Sec'
            ? 1000*5
            : filterState==='5 Mins'
            ? 1000*60*5
            : 1000*60*60
        )
        return ()=>{clearInterval(timeInterval)}
    },[filterState])

    if(!props.control?.permissions?.CS_VIEW)
        return(<NoPermission/>)

    return(
        <div className="flex-col bg-[#0C0D14] text-white flex-1 text-[14px]">
            <div style={{...LEFTRIGHT_PADDING}} className='pb-64'>
                <div className="flex flex-row my-[40px] items-center justify-between">
                    <div className="flex flex-row items-center">
                        <TitleLeftSvg className='mr-[9px]'/>
                        <div className="text-[24px] text-white font-bold ">Confined Space</div>
                    </div>
                    <div className='flex flex-row max-w-[552px] flex-1 items-center'>
                        <div className='text-white mr-2'>Refresh Time</div>
                        <div className='flex flex-row max-w-[440px] flex-1 items-center border border-[#00202A] rounded-full overflow-hidden'>
                            {['5 Sec', '1 Min', '5 Mins', '1 Hr'].map((time) => (
                                <div key={time} onClick={() => setFilterState(time)} className='flex-1 text-center text-white py-[2px] cursor-pointer' style={{borderRightColor:'#00202A', borderRightWidth: time === 'Custom' ? 0 : 1, backgroundColor: filterState === time ? '#00376A' : 'transparent'}}>
                                    {time}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="flex flex-row justify-between items-center text-white">
                    <div className="flex flex-row mt-[27px]">
                        <div className="subpage_selected">Confined Space</div>
                        <div className="subpage_not_selected" onClick={() => navigate('/confined-space/history')}>Historical Data</div>
                        <div className="subpage_not_selected" onClick={() => navigate('/confined-space/alert')}>Alert</div>
                    </div>
                    <div className='flex flex-row items-center gap-[4px]'>
                        {props.control?.permissions?.CS_CONFIG&&<div className="button_blue" onClick={()=>{navigate('/confined-space/setting')}} >
                                <DeviceSettingSvg/> All Device Setting
                            </div>}
                        {props.control?.permissions?.CS_CREATE&&<div className='button_blue' onClick={handleStartCreate}>
                            + Add Device
                        </div>}
                    </div>
                </div>
                




                <div className="mt-[25px] flex-wrap	flex flex-row gap-[16px] items-center justify-stretch w-full mb-10" style={{borderTop:'.5px solid #ffffff40',borderBottom:'.5px solid #ffffff40'}}>
                    <div className="py-[32px] w-full">
                        <div className="flex flex-row text-[10px]">
                            <div className="table_header" style={{width:50}}>Item</div>
                            <div className="table_header flex-[2]">Serial Number</div>
                            <div className="table_header flex-[2]">Name</div>
                            <div className="table_header flex-[1]">O2</div>
                            <div className="table_header flex-[1]">Temperature</div>
                            <div className="table_header flex-[1]">PM2.5</div>
                            <div className="table_header flex-[1]">CO</div>
                            <div className="table_header flex-[1]">CO2</div>
                            <div className="table_header flex-[1]">H2S</div>
                            <div className="table_header flex-[1]">CH4</div>
                            <div className="table_header flex-[1]">Compostable Gas</div>
                            <div className="table_header flex-[1]">Last Update</div>
                            <div className="w-[100px]"/>
                        </div>
                        {data.map((item, idx) =>
                            <div key={item?._id||idx} className="table_row" style={{overflowWrap: 'break-word',backgroundColor:idx%2===1?'#FFFFFF0D':'transparent'}}>
                                <div className="table_item" style={{width:50}}>{((page-1)*limit+ idx + 1 )}</div>
                                <div className="table_item flex-[2]">{item?.id}</div>
                                <div className="table_item flex-[2]">{item?.data?.name}</div>

                                <div className="table_item flex-[1]"><ValueDisplay field='gas_o2' item={item} gasConfig={gasConfig} unit='%'/></div>
                                <div className="table_item flex-[1]"><ValueDisplay field='temperature' item={item} gasConfig={gasConfig} unit='°C'/></div>
                                <div className="table_item flex-[1]"><ValueDisplay field='particle_pm25' item={item} gasConfig={gasConfig} unit='ug/m3'/></div>
                                <div className="table_item flex-[1]"><ValueDisplay field='gas_co' item={item} gasConfig={gasConfig} unit='PPB'/></div>
                                <div className="table_item flex-[1]"><ValueDisplay field='gas_co2' item={item} gasConfig={gasConfig} unit='PPM'/></div>
                                <div className="table_item flex-[1]"><ValueDisplay field='gas_h2s' item={item} gasConfig={gasConfig} unit='PPB'/></div>
                                <div className="table_item flex-[1]"><ValueDisplay field='gas_ch4' item={item} gasConfig={gasConfig} unit='PPM'/></div>
                                <div className="table_item flex-[1]"><ValueDisplay field='gas_ex' item={item} gasConfig={gasConfig} unit='% LEL'/></div>
                                <div className="table_item flex-[1] text-[10px]">{new Date(item?.gasData?.timestamp).toLocaleDateString()}<br/>{new Date(item?.gasData?.timestamp).toLocaleTimeString()}</div>
                                <div className="table_item w-[100px] flex flex-row items-center gap-[8px]">
                                    {props.control?.permissions?.CS_EDIT&&<div className='cursor-pointer bg-[#00202A] p-[8px] rounded-[7px]' onClick={handleStartUpdating(item)}><EditIconSvg/></div>}
                                    {props.control?.permissions?.CS_DELETE&&<div onClick={handleStartDeleteRecord(item)}><BinSvg className='cursor-pointer' color='#ffffff40'/></div>}
                                </div>
                            </div>
                        )}
                    </div>
                </div>


                <PageSelector
                    handleSelectPage={handleSelectPage}
                    page={page}
                    total={total}
                    totalPage={totalPage}
                />
            </div>

            {/*create record popup*/}
            {isCreating&&<div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)'}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[300px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                    <div className='flex flex-row items-center space-between w-full'>
                        <div className='font-bold'>Add Confined Space</div>          
                    </div>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={handleCancelCreate}>X</div>         

                    <div className='font-bold text-sm mt-10'>Serial Number*</div>
                    <input value={(isCreating?.id)||""} onChange={handleCreateRecordInput('id')} placeholder='serial number' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>

                    <div className='font-bold text-sm mt-5'>Device Name*</div>
                    <input value={(isCreating?.data?.name)||""} onChange={handleCreateRecordInput('name')} placeholder='deivice name' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>

                    <div className='font-bold text-sm mt-5'>Longitude</div>
                    <input value={(isCreating?.data?.longitude)||""} onChange={handleCreateRecordInput('longitude')} placeholder='longitude' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>
                    <div className='font-bold text-sm mt-5'>Latitude</div>
                    <input value={(isCreating?.data?.latitude)||""} onChange={handleCreateRecordInput('latitude')} placeholder='latitude' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>

                    <div className='w-full text-[red] text-xs'>{createError}</div>

                    <div onClick={handleCreateRecord} className='w-full text-[#63BDFF] bg-[#00202A] mt-5 py-[6px] flex flex-row item-center justify-center rounded-[99px] hover:bg-[#10303A] hover:font-bold select-none cursor-pointer'>
                        Add Confined Space
                    </div>
                    <div onClick={handleCancelCreate} className='w-full text-[#ffffffa0] bg-[#ffffff40] mt-2 py-[6px] flex flex-row item-center justify-center rounded-[99px] hover:bg-[#ffffff60] hover:font-bold select-none cursor-pointer'>
                        Cancel
                    </div>
                </div>
            </div>}
            {/*end of create record popup*/}

            {/*edit record popup*/}
            {isUpdating&&<div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)'}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[300px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                    <div className='flex flex-row items-center space-between w-full'>
                        <div className='font-bold'>Edit Confined Space</div>          
                    </div>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={handleCancelUpdate}>X</div>         

                    <div className='font-bold text-sm mt-10'>Serial Number*</div>
                    <input value={(isUpdating?.id)||""} onChange={handleEditRecordInput('id')} placeholder='serial number' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>

                    <div className='font-bold text-sm mt-5'>Device Name*</div>
                    <input value={(isUpdating?.data?.name)||""} onChange={handleEditRecordInput('name')} placeholder='deivice name' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>
                    
                    <div className='font-bold text-sm mt-5'>Longitude</div>
                    <input value={(isUpdating?.data?.longitude)||""} onChange={handleEditRecordInput('longitude')} placeholder='longitude' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>
                    <div className='font-bold text-sm mt-5'>Latitude</div>
                    <input value={(isUpdating?.data?.latitude)||""} onChange={handleEditRecordInput('latitude')} placeholder='latitude' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>

                    <div className='w-full text-[red] text-xs'>{updateError}</div>

                    <div onClick={handleUpdate} className='w-full text-[#63BDFF] bg-[#00202A] mt-5 py-[6px] flex flex-row item-center justify-center rounded-[99px] hover:bg-[#10303A] hover:font-bold select-none cursor-pointer'>
                        Update
                    </div>
                    <div onClick={handleCancelUpdate} className='w-full text-[#ffffffa0] bg-[#ffffff40] mt-2 py-[6px] flex flex-row item-center justify-center rounded-[99px] hover:bg-[#ffffff60] hover:font-bold select-none cursor-pointer'>
                        Cancel
                    </div>
                </div>
            </div>}
            {/*end of edit record popup*/}

            {/*delete record popup*/}
            {deleteRecord&&<div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)'}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative' style={{border:'.5px solid #FFFFFF80'}}>
                    <div className='flex flex-row items-center space-between w-full pr-[64px]'>
                        <div className='font-bold'>Confirm Delete?</div>          
                    </div>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={handleCancelDeleteRecord}>X</div>         

                    <div className='flex flex-row items-center space-between w-full mt-10'>
                        <div className='text-sm'>Device Name: {deleteRecord?.data?.name||'-'}</div>          
                    </div>

                    <div className='w-full text-[red] text-xs'>{deleteRecordError}</div>

                    <div onClick={handleCancelDeleteRecord} className='w-full text-[white] bg-[#ffffff20] mt-10 py-[6px] flex flex-row item-center justify-center rounded-[99px] hover:bg-[#ffffff30] hover:font-bold select-none cursor-pointer'>
                        Cancel
                    </div>
                    <div onClick={handleDeleteRecord} className='w-full text-[#FF969C] bg-[#FF969C20] mt-2 py-[6px] flex flex-row item-center justify-center rounded-[99px] hover:bg-[#FF969C30] hover:font-bold select-none cursor-pointer'>
                        Delete
                    </div>
                </div>
            </div>}
            {/*end of delete record popup*/}
            {isLoading&&<LoadingPage/>}
        </div>

    )
}

function ValueDisplay(props){
    const value = props.item?.gasData?.data?.[props.field]
    const isEvacuate = props.gasConfig?.[props.field]?.enabled && (
        props.field==='gas_o2'?
        ( (value <= props.gasConfig?.[props.field]?.evacuate_lower) || (value >= props.gasConfig?.[props.field]?.evacuate_upper)  )
        : (value >= props.gasConfig?.[props.field]?.evacuate)
    )
    const isWarning = props.gasConfig?.[props.field]?.enabled && (
        props.field==='gas_o2'?
        ( (value <= props.gasConfig?.[props.field]?.warning_lower) || (value >= props.gasConfig?.[props.field]?.warning_upper)  )
        : (value >= props.gasConfig?.[props.field]?.warning)
    )
    return(
        <div style={{color:isEvacuate?"#F47070":isWarning?'#FFE454':'white'}}>
            {value} {props.unit}
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Component)

