import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { LEFTRIGHT_PADDING } from "../config/style.config";
import TitleLeftSvg from '../svgs/TitleLeftSvg'
import LoadingPage from "../components/Loading";
import { Login } from "../actions/actions";
import { createNewRole, fetchRolesList, editRole } from "../services/users.service";
import ToggleButton from '../components/ToggleButton'


const PERMISSIONS =(p)=>[
    [
        {
            label:'CMP',
            permissions:[
                {code:p?.CMP?.[0],text:'View all data'}
            ]
        },
        {
            label:'Summary Page',
            permissions:[
                {code:p?.SUMMARY?.[0],text:'View all data'},
                {code:p?.SUMMARY?.[1],text:'Allowed to hide alerts'}
            ]
        },
        {
            label:'Tracking System',
            permissions:[
                {code:p?.TRACKING?.[0],text:'View all data'},
                {code:p?.TRACKING?.[1],text:'Allowed to add record'},
                {code:p?.TRACKING?.[2],text:'Allowed to edit record'},
                {code:p?.TRACKING?.[3],text:'Allowed to delete record'},
            ]
        },
        {
            label:'Permit-to-work system',
            permissions:[
                {code:p?.PERMIT?.[0],text:'View all data'},
                {code:p?.PERMIT?.[1],text:'Allowed to issue'},
                {code:p?.PERMIT?.[2],text:'Allowed to approve'},
                {code:p?.PERMIT?.[3],text:'Allowed to edit permit information'},
                {code:p?.PERMIT?.[4],text:'Allowed to delete permit'},
                {code:p?.PERMIT?.[5],text:'Allowed to manage template'},
            ]
        }
    ],[
        {
            label:'Smart Lock',
            permissions:[
                {code:p?.LOCK?.[0],text:'View all information'},
                {code:p?.LOCK?.[1],text:'Allowed to add a device'},
                {code:p?.LOCK?.[2],text:'Edit device infotmation'},
                {code:p?.LOCK?.[3],text:'Allowed to remote unlock'},
                {code:p?.LOCK?.[4],text:'Allowed to delete device'},
                {code:p?.LOCK?.[5],text:'Receive lock alerts'},
            ]
        },
        {
            label:'Danger Zone',
            permissions:[
                {code:p?.DZ?.[0],text:'View all information'},
                {code:p?.DZ?.[1],text:'Allowed to add danger zone'},
                {code:p?.DZ?.[2],text:'Edit danger zone infotmation'},
                {code:p?.DZ?.[3],text:'Allowed to delete danger zone'},
                {code:p?.DZ?.[4],text:'Receive danger zone alerts'},
            ]
        },
        {
            label:'Smart Watch',
            permissions:[
                {code:p?.WATCH?.[0],text:'View all information'},
                {code:p?.WATCH?.[1],text:'Allowed to add a device'},
                {code:p?.WATCH?.[2],text:'Edit device infotmation'},
                {code:p?.WATCH?.[3],text:'Allowed to delete device'},
                {code:p?.WATCH?.[4],text:'Receive smart watch alerts'},
            ]
        },
        {
            label:'AI Camera',
            permissions:[
                {code:p?.CAMERA?.[0],text:'View all information'},
                {code:p?.CAMERA?.[1],text:'Allowed to add a device'},
                {code:p?.CAMERA?.[2],text:'Edit device infotmation'},
                {code:p?.CAMERA?.[3],text:'Allowed to delete device'},
                {code:p?.CAMERA?.[4],text:'Receive AI cameras alerts'},
            ]
        }
         //"VR":["VR_VIEW","VR_CREATE","VR_EDIT","VR_DELETE"],"USER":["USER_VIEW","USER_CREATE","USER_EDIT","USER_DELETE","USER_PASSWORD","USER_ALERT"]}
   
    ],[
        {
            label:'Confined Space',
            permissions:[
                {code:p?.CS?.[0],text:'View all information'},
                {code:p?.CS?.[1],text:'Allowed to add a device'},
                {code:p?.CS?.[2],text:'Edit device infotmation'},
                {code:p?.CS?.[3],text:'Allowed to delete device'},
                {code:p?.CS?.[4],text:'Adjust the altering range'},
                {code:p?.CS?.[5],text:'Receive confined space alerts'},
            ]
        },
        {
            label:'VR',
            permissions:[
                {code:p?.VR?.[0],text:'View all information'},
                {code:p?.VR?.[1],text:'Allowed to add record'},
                {code:p?.VR?.[2],text:'Edit record infotmation'},
                {code:p?.VR?.[3],text:'Allowed to delete record'},
            ]
        },
        {
            label:'User Management',
            permissions:[
                {code:p?.USER?.[0],text:'View all information'},
                {code:p?.USER?.[1],text:'Allowed to add user'},
                {code:p?.USER?.[2],text:'Edit user infotmation'},
                {code:p?.USER?.[3],text:'Allowed to delete user'},
                {code:p?.USER?.[4],text:'Reset password for users'},
                {code:p?.USER?.[5],text:'Manage the alert receiving group'},
            ]
        }
    ]
]


function Screen(props){
    const navigate = useNavigate();
    const [isLoading,setisLoading]=useState(false)
    const [roles,setroles]=useState([])
    const [selectedRole,setselectedRole]=useState(null)
    const [permissions,setPermissions]=useState({})

    const fetchRoles = async()=>{
        let result = await fetchRolesList(props.token?.token,{projectId:props.control?.projectId},{sort:{name:1}})
        if(result.errno===10001){
            props.dispatch(Login(false))
            return alert('Session Expired');
        }else if(result?.result){
            setroles(result?.result);
            setselectedRole(result?.result?.[0])
            setPermissions(result?.permissions)
        }
    }
    const togglePermission=(p)=>()=>{
        let data= {...selectedRole}
        data.permissions[p]=!data.permissions[p]
        setselectedRole(data)
    }

    const handleSave=async ()=>{
        if(!selectedRole)return
        if(isLoading)return
        setisLoading(true)
        let result = await editRole(props.token?.token,selectedRole?._id,{
            permissions:selectedRole.permissions,
            name: selectedRole?.name,
        })
        if(result.errno===10001){
            setisLoading(false)
            props.dispatch(Login(false))
            return alert('Session Expired');
        }else if(result?.result){
            fetchRoles()
        }else{
            alert(result?.message)
        }
        setisLoading(false);
    }


    //new record
    const [isCreating,setisCreating]=useState(false)
    const [createError,setCreateError]=useState(null)
    const handleStartCreate =()=>{setisCreating({})}
    const handleCancelCreate=()=>{setisCreating(null);setCreateError(null)}
    const handleCreateRecordInput=(field)=>(e)=>{
        let d= {...isCreating}
        d[field]=e.target.value
        setisCreating(d)
    }
    const handleCreateSmartWatch=async()=>{
        if(!isCreating?.name){setCreateError('Name cannot be empty.');return}
        if(isLoading)return
        setisLoading(true)
        let updateData = {
            projectId: props.control?.projectId,
            name: isCreating?.name,
            permissions: {},
        }
        let result = await createNewRole(props.token?.token,updateData)
        if(result.errno){
            setCreateError(`Error: ${JSON.stringify(result?.message)}`)
        }else{
            handleCancelCreate()
            fetchRoles()
        }
        setisLoading(false);
    }
    //end of new record


    useEffect(()=>{fetchRoles()},[props.control.projectId])
    return(
        <div className="flex-col bg-[#0C0D14] flex-1 text-white text-[14px]">
            <div style={{...LEFTRIGHT_PADDING}} className='pb-64'>

                <div className="flex flex-row items-center justify-between mt-[40px]">
                    <div className="flex flex-row items-end">
                        <TitleLeftSvg className='mr-[9px]'/>
                        <div className="text-[24px] text-white font-bold ">User Management</div>
                        <div className="text-[16px] text-white ml-[16px]">Your identity: {props?.token?.usertype==='admin'?'Super admin':(props.control?.userData?.role||-'')}</div>
                    </div>
                    <div className="flex flex-row items-center">
                        <div className="button_white" onClick={()=>{navigate('/user-management')}}>
                            Cancel
                        </div>
                        <div className="button_blue ml-[16px]" onClick={handleSave}>
                            Save
                        </div>
                    </div>
                </div>

                <div className="flex flex-row mt-[27px] items-center justify-between">
                    <div className="flex flex-row">
                        {roles?.map(item=><div key={item?._id}>
                            <div className={selectedRole?._id===item?._id?"subpage_selected":"subpage_not_selected"} onClick={()=>{setselectedRole(item)}}>{item?.name}</div>
                        </div>)}
                    </div>
                    <div className="button_blue" onClick={handleStartCreate}>
                        + Add Role
                    </div>
                </div>

                {selectedRole&&<div className="flex flex-row w-full mt-10" style={{gap:24}}>
                   {PERMISSIONS(permissions)?.map((col,ci)=>
                        <div key={ci} className="flex-1 flex flex-col" style={{gap:24}}>
                            {col?.map((row,ri)=>
                                <div key={ri} className="flex-1 flex flex-row" style={{border: '.5px solid #ffffff80',padding:16}}>
                                    <div className="flex flex-row items-start font-bold flex-[2]">
                                        <TitleLeftSvg className='mr-[9px]' width={10}/>{row?.label||'-'}
                                    </div>
                                    <div className="flex-[3] flex flex-col justify-start pl-[14px]" style={{borderLeft:'.5px solid #ffffff80'}}>
                                    {row?.permissions?.map((p,pi)=>
                                        <div key={p?.code||pi} className="flex flex-row items-center mb-2">
                                            <div className="flex-1">{p.text}</div>
                                            <ToggleButton value={selectedRole?.permissions?.[p?.code]} onClick={togglePermission(p.code)}/>
                                        </div>
                                    )}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>}
            </div>

            {/*create record popup*/}
            {isCreating&&<div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)'}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[300px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                    <div className='flex flex-row items-center space-between w-full'>
                        <div className='font-bold'>Add Role</div>          
                    </div>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={handleCancelCreate}>X</div>         

                    <input value={(isCreating?.name)||""} onChange={handleCreateRecordInput('name')} placeholder='Name' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[2px] mt-5' style={{border:'.5px solid #ffffff80'}}/>

                    <div className='w-full text-[red] text-xs'>{createError}</div>

                    <div onClick={handleCreateSmartWatch} className='button_blue w-full mt-5'>
                        Create
                    </div>
                </div>
            </div>}
            {/*end of create record popup*/}

            {isLoading&&<LoadingPage/>}
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Screen)


