import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect } from 'react-redux';
import { LEFTRIGHT_PADDING } from "../config/style.config";
import PageSelector from '../components/PageSelector';
import EventTypeComp from '../components/LockPage/EventTypeComp';
import {Login} from '../actions/actions'
import LoadingPage from "../components/Loading";
import NoPermission from './NoPermission';
import TitleLeftSvg from '../svgs/TitleLeftSvg'
import ChartChainLink from '../svgs/ChartChainLink'
import SmallSearchSvg from '../svgs/watchPage/SmallSearchSvg'
import CogSvg from '../svgs/watchPage/CogSvg'
import BinSvg from '../svgs/watchPage/BinSvg'
import LockSvg from '../svgs/lockPage/LockSvg'
import DropdownMenu from '../components/DropdownMenu'
import { createLockRecord, deleteLockRecord, editLockRecord, fetchSmartLock, remoteUnlock } from "../services/locks.service";
//
const CHART_TITLE = [
    {title: 'Item', field: null, flex: 1},
    {title: 'Connection', field: '', flex: 2},
    {title: 'Device Name', field: 'name', flex: 4},
    {title: 'Serial Number', field: 'id', flex: 4},
    {title: 'Status', field: '', flex: 2},
    {title: 'Remote Unlock', field: '', flex: 2},
    {title: 'Battery', field: '', flex: 2},
    {title: 'Location', field: '', flex: 2},
    {title: 'Last Update', field: 'modifyDate', flex: 2},
    {title: '', field: '', flex: 2},
]

function SmartLockP2(props){
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [isLoading,setisLoading] = useState(false)

     //filter
     const statusFilter =  searchParams.get('status')||'All';
     const selectStatusFilter=(item,index)=>{ setSearchParams({page:1,status:item}) }
     //end of filter

    //paging
    const page =  parseInt(searchParams.get('page'))||1;
    const limit=10;
    const [total,setTotal]=useState(0)
    const [activeTotal,setactiveTotal]=useState(0)
    const totalPage = Math.ceil(total/limit)||1
    const handleSelectPage=(p)=>async ()=>{
        if(p > totalPage || p < 1) return;
        searchParams.delete('page')
        searchParams.set('page', p)
        setSearchParams(searchParams)
    }
    //end of paging

    //search
    const searchTxt =  searchParams.get('search')||'';
    const handleSearch=(e)=>{setSearchParams({page:1,search:e.target.value,status:statusFilter})}
    //send of search

    const [data,setData]=useState([])
    const sortBy={modifyDate:-1};
    const fetchData=async(p=page,l=limit,st=sortBy)=>{
        const filter={ projectId:props.control?.projectId }
        if(searchTxt)filter['data.name']={"$regex":searchTxt, "$options":'i'}
        if(statusFilter!=='All')filter['data.isConnected']=statusFilter==='Active'?true:{$ne:true}
        let result = await fetchSmartLock(props.token.token,filter,{ skip: l*(p-1), limit:l , sort:st})
        if(result.errno===10001){
            props.dispatch(Login(false))
            return alert('Session Expired');
        }else if(result?.result){
            setData(result?.result);
            setTotal(result?.count||0)
            setactiveTotal(result?.activeCount||0)
        }
    }


    //new record
    const [isCreating,setisCreating]=useState(false)
    const [createError,setCreateError]=useState(null)
    const handleStartCreate =()=>{setisCreating({data:{}})}
    const handleCancelCreate=()=>{setisCreating(null);setCreateError(null)}
    const handleCreateRecordInput=(field)=>(e)=>{
        let d= {...isCreating}
        d.data[field]=e.target.value
        if(field==='id')d.id=e.target.value
        setisCreating(d)
    }
    const handleCreateRecord=async()=>{
        if(!isCreating?.id){setCreateError('Serial Number cannot be empty.');return}
        if(!isCreating?.data?.name){setCreateError('Device Name cannot be empty.');return}
        if(isLoading)return
        setisLoading(true)
        let updateData = {
            id: isCreating?.id,
            projectId: props.control?.projectId,
            data:{
                id:isCreating?.id,
                name:isCreating?.data?.name,
                longitude: isCreating?.data?.longitude? Number(isCreating?.data?.longitude): null,
                latitude: isCreating?.data?.latitude? Number(isCreating?.data?.latitude): null
            }
        }
        let result = await createLockRecord(props.token?.token,updateData)
        if(result.errno){
            setCreateError(`Error: ${JSON.stringify(result?.message)}`)
        }else{
            handleCancelCreate()
            fetchData()
        }
        setisLoading(false);
    }
    //end of new record
    
    //edit record
    const [isUpdating,setisUpdating]=useState(false)
    const [updateError,setupdateError]=useState(null)
    const handleStartUpdating =(item,index)=>()=>{setisUpdating(JSON.parse(JSON.stringify(item)))}
    const handleCancelUpdate=()=>{setisUpdating(null);setupdateError(null)}
    const handleEditRecordInput=(field)=>(e)=>{
        let d= {...isUpdating}
        d.data[field]=e.target.value
        if(field==='id')d.id=e.target.value
        setisUpdating(d)
    }
    const handleUpdateSmartWatch=async()=>{
        if(!isUpdating?.id){setupdateError('Serial Number cannot be empty.');return}
        if(!isUpdating?.data?.name){setupdateError('Device Name cannot be empty.');return}
        if(isLoading)return
        setisLoading(true)
        let updateData = {
            id: isUpdating?.id,
            'data.id':isUpdating?.id,
            'data.name':isUpdating?.data?.name,
            'data.longitude': isUpdating?.data?.longitude? Number(isUpdating?.data?.longitude): null,
            'data.latitude': isUpdating?.data?.latitude? Number(isUpdating?.data?.latitude): null
        }
        let result = await editLockRecord(props.token?.token,isUpdating?._id,updateData)
        if(result.errno){
            setupdateError(`Error: ${JSON.stringify(result?.message)}`)
        }else{
            handleCancelUpdate()
            fetchData()
        }
        setisLoading(false);
    }
    //end of edit record
    
    
    //delete params
    const [deleteRecord,setdeleteRecord]=useState(null)
    const [deleteRecordError,setdeleteRecordError]=useState(null)
    const handleCancelDeleteRecord=()=>{setdeleteRecord(null)}
    const handleStartDeleteRecord=(item,index)=>()=>{ setdeleteRecord(item) }
    const handleDeleteRecord=async()=>{
        if(isLoading)return
        setisLoading(true)
        let result = await deleteLockRecord(props.token?.token,deleteRecord?._id);
        if(result.errno){
            setdeleteRecordError(`Error: ${JSON.stringify(result?.message)}`)
        }else{
            handleCancelDeleteRecord()
            fetchData()
        }
        setisLoading(false);
    }
    //end of delete params


    //remote unlock
    const [isUnlocking,setisUnlocking]=useState(null)
    const [remoteUnlockMessage,setremoteUnlockMessage]=useState('')
    const handleDismissRemoteUnlock=()=>{
        setisUnlocking(null);
        setremoteUnlockMessage('');
    }
    const handleRemoteUnlock=(item,index)=>async()=>{
        if(!props.control?.permissions?.LOCK_REMOTE)return
        if(isLoading)return
        setisLoading(true);
        setisUnlocking(item);
        let result = await remoteUnlock(props.token?.token,item?.id)
        if(result?.result?.returnCode==="200")
            setremoteUnlockMessage('Unlock Success')
        else if(result?.result.returnCode==="8007"){
            setremoteUnlockMessage('Unlock Failed. Unknown Device')
        }else{
            setremoteUnlockMessage(`Unlock Failed: ${result?.result?.returnMsg}`)
        }
        setisLoading(false)
    }
    //end of remote unlock


    useEffect(() => {
        const timer = setTimeout(() => {fetchData()}, 500)
        return () => clearTimeout(timer)
    },[searchParams])

    useEffect(()=>{
        setSearchParams({page:1,search:''})
    },[props.control.projectId])


    if(!props.control?.permissions?.LOCK_VIEW)
        return(<NoPermission/>)

    return(
        <div className="flex-col bg-[#0C0D14] flex-1 text-[14px] relative">
            <div style={{...LEFTRIGHT_PADDING}} className="mb-96">
                <div className="flex flex-row mt-[40px]">
                    <TitleLeftSvg className='mr-[9px]'/>
                    <div className="text-[24px] text-white font-bold ">Smart Lock</div>
                </div>
                <div className="flex flex-row mt-[27px]">
                    <div className="subpage_not_selected" onClick={() => navigate('/smartlock')}>
                        Alert
                    </div>
                    <div className="subpage_selected">
                        Device Management
                    </div>
                </div>
                <div className="mt-[24px] pt-[13px] flex flex-row items-center justify-between">
                        {/* <div className="rounded-full flex flex-row items-center justify-between flex-1 max-w-[380px] px-[16px] py-[2px]" style={{borderColor:'#FFFFFF80', borderWidth:1}}>
                            <input className="bg-transparent h-[21px] flex-1 mr-[11px] text-white" placeholder="All Event Type"></input>
                            <SmallPointDownSvg />
                        </div> */}
                        <div/>
                        <div className="flex flex-row gap-2">
                            <DropdownMenu select={selectStatusFilter} options={['All','Active','Sleep']} value={statusFilter==='All'?'Status':statusFilter}/>
                            <div className="rounded-full flex flex-row items-center justify-between max-w-[225px] px-[16px] py-[2px]" style={{borderColor:'#FFFFFF80', borderWidth:1}}>
                                <SmallSearchSvg className='mr-[12px]' />
                                <input onChange={handleSearch} className="bg-transparent h-[21px] text-white min-w-0" placeholder="Search Name"></input>
                            </div>
                        </div>
                </div>
                <div className="mt-[16px]"style={{}}>
                    <div className="flex flex-row items-center justify-between py-[24px] px-[16px]" style={{borderBottomWidth: 1, borderBottomColor:'#ffffff80'}}>
                        <div className="flex flex-row items-center">
                            <div className="text-white text-[18px] font-bold flex items-center">
                                Real-time Smart Lock
                            </div>
                            <ChartChainLink className="mx-[10px] cursor-pointer"/>
                        </div>
                        <div className="flex flex-row items-center overflow-hidden gap-2">
                            <div className='text-[14px]' style={{color:'#29FFD9'}}>
                                Active Smart Lock: {activeTotal||0}
                            </div>
                            {props.control?.permissions?.LOCK_CREATE&&
                                <div className="text-[#63BDFF] text-[12px] px-[16px] py-[2px] font-bold cursor-pointer rounded-full bg-[#00202A] hover:bg-[#10303A]" onClick={handleStartCreate} >
                                    + Add Smart Lock
                                </div>
                            }
                        </div>
                    </div>
                    <div className="py-[32px]">
                        <div className="flex flex-row text-[10px]">
                            {CHART_TITLE.map((obj, i) => (
                                <div key={'title'+obj.title} className="table_header" style={{flex: obj.flex || 1, flexShrink: obj.srink ? 2 : 0}}>
                                    {obj.title}
                                    {/* {['Battery','Event','Date','Heart Rate','Body Temperature','Standstill'].includes(obj.title) && <SmallPointLeftSvg style={{transform: 'rotate(-90deg)'}} />} */}
                                </div>
                            ))}
                        </div>
                        {data.map((item, idx) =>
                            <div key={item?._id||idx} className="table_row" style={{overflowWrap: 'break-word',backgroundColor:idx%2===1?'#FFFFFF0D':'transparent'}} >
                                {CHART_TITLE.map((h, i) => 
                                    <div key={'watchP2' + h.title + i} className="table_item" style={{flex: h.flex}}>
                                        {
                                            h.title === 'Item' ? ((page-1)*limit+ idx + 1 ):
                                            h.title === 'Location' ? <div className="text-[10px]">
                                                long: {item?.data?.longitude||'-'}<br/>
                                                lat: {item?.data?.latitude||'-'}
                                            </div> :
                                            h.title === 'Remote Unlock' ? 
                                                <div className="text-white flex flex-row gap-2 mr-[21px] cursor-pointer" style={{opacity:props.control?.permissions?.LOCK_REMOTE?1:.1}} onClick={handleRemoteUnlock(item,idx)}>
                                                    <div className="flex flex-row" style={{width:35, height: 22, borderRadius:99, backgroundColor:'rgb(41, 255, 217)', padding:2, justifyContent:'end'}}>
                                                        <div style={{width:18, height: 18, borderRadius:99, backgroundColor:'#00202A'}}/>
                                                    </div>
                                                </div>
                                            :
                                            h.title === 'Battery' ? <div className="">{item?.data?.battery||'-'}% </div> :
                                            h.title === 'Last Update' ? <div className="text-[10px]">{new Date(item?.[h?.field]).toLocaleDateString()||'-'}<br/>{new Date(item?.[h?.field]).toLocaleTimeString()||""}</div> :
                                            h.title === 'Connection' ? <EventTypeComp type={undefined} SA={item?.data?.isConnected||false}/> :
                                            h.title === 'Status' ? (<div className="flex flex-row gap-1 items-center">
                                                <LockSvg width={16}/>{item?.data.status||'-'}
                                            </div>) :
                                            h.title === '' ? (<div className="flex flex-row items-center justify-evenly gap-[4px]">
                                                {props.control?.permissions?.LOCK_EDIT&&<div className="w-[30px] h-[30px] flex items-center justify-center  bg-[#00202A] hover:bg-[#10303A] rounded-md cursor-pointer" onClick={handleStartUpdating(item,idx)}><CogSvg color='#007EB5'/></div>}
                                                {props.control?.permissions?.LOCK_DELETE&&<div className="w-[30px] h-[30px] flex items-center justify-center rounded-md cursor-pointer" onClick={handleStartDeleteRecord(item,idx)}><BinSvg color='#777676'/></div>}
                                            </div>) : 
                                            (item.data[h?.field]) || '-'
                                        }
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <PageSelector
                        handleSelectPage={handleSelectPage}
                        page={page}
                        total={total}
                        totalPage={totalPage}
                    />
                </div>
            </div>


            {/*create record popup*/}
            {isCreating&&<div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)'}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[300px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                    <div className='flex flex-row items-center space-between w-full'>
                        <div className='font-bold'>Add Smart Lock</div>          
                    </div>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={handleCancelCreate}>X</div>         

                    <div className='font-bold text-sm mt-10'>Serial Number*</div>
                    <input value={(isCreating?.id)||""} onChange={handleCreateRecordInput('id')} placeholder='serial number' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>

                    <div className='font-bold text-sm mt-5'>Device Name*</div>
                    <input value={(isCreating?.data?.name)||""} onChange={handleCreateRecordInput('name')} placeholder='deivice name' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>

                    <div className='font-bold text-sm mt-5'>Longitude</div>
                    <input value={(isCreating?.data?.longitude)||""} onChange={handleCreateRecordInput('longitude')} placeholder='longitude' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>
                    <div className='font-bold text-sm mt-5'>Latitude</div>
                    <input value={(isCreating?.data?.latitude)||""} onChange={handleCreateRecordInput('latitude')} placeholder='latitude' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>

                    <div className='w-full text-[red] text-xs'>{createError}</div>

                    <div onClick={handleCreateRecord} className='w-full text-[#63BDFF] bg-[#00202A] mt-5 py-[6px] flex flex-row item-center justify-center rounded-[99px] hover:bg-[#10303A] hover:font-bold select-none cursor-pointer'>
                        Add Smart Lock
                    </div>
                    <div onClick={handleCancelCreate} className='w-full text-[#ffffffa0] bg-[#ffffff40] mt-2 py-[6px] flex flex-row item-center justify-center rounded-[99px] hover:bg-[#ffffff60] hover:font-bold select-none cursor-pointer'>
                        Cancel
                    </div>
                </div>
            </div>}
            {/*end of create record popup*/}


            {/*edit record popup*/}
            {isUpdating&&<div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)'}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[300px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                    <div className='flex flex-row items-center space-between w-full'>
                        <div className='font-bold'>Edit Smart Watch</div>          
                    </div>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={handleCancelUpdate}>X</div>         

                    <div className='font-bold text-sm mt-10'>Serial Number*</div>
                    <input value={(isUpdating?.id)||""} onChange={handleEditRecordInput('id')} placeholder='serial number' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>

                    <div className='font-bold text-sm mt-5'>Device Name*</div>
                    <input value={(isUpdating?.data?.name)||""} onChange={handleEditRecordInput('name')} placeholder='deivice name' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>

                    <div className='font-bold text-sm mt-5'>Longitude</div>
                    <input value={(isUpdating?.data?.longitude)||""} onChange={handleEditRecordInput('longitude')} placeholder='longitude' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>
                    <div className='font-bold text-sm mt-5'>Latitude</div>
                    <input value={(isUpdating?.data?.latitude)||""} onChange={handleEditRecordInput('latitude')} placeholder='latitude' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>

                    <div className='w-full text-[red] text-xs'>{updateError}</div>

                    <div onClick={handleUpdateSmartWatch} className='w-full text-[#63BDFF] bg-[#00202A] mt-5 py-[6px] flex flex-row item-center justify-center rounded-[99px] hover:bg-[#10303A] hover:font-bold select-none cursor-pointer'>
                        Update
                    </div>
                    <div onClick={handleCancelUpdate} className='w-full text-[#ffffffa0] bg-[#ffffff40] mt-2 py-[6px] flex flex-row item-center justify-center rounded-[99px] hover:bg-[#ffffff60] hover:font-bold select-none cursor-pointer'>
                        Cancel
                    </div>
                </div>
            </div>}
            {/*end of edit record popup*/}

            {/*delete record popup*/}
            {deleteRecord&&<div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)'}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative' style={{border:'.5px solid #FFFFFF80'}}>
                    <div className='flex flex-row items-center space-between w-full pr-[64px]'>
                        <div className='font-bold'>Confirm Delete?</div>          
                    </div>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={handleCancelDeleteRecord}>X</div>         

                    <div className='flex flex-row items-center space-between w-full mt-10'>
                        <div className='text-sm'>Device Name: {deleteRecord?.data?.name||'-'}</div>          
                    </div>

                    <div className='w-full text-[red] text-xs'>{deleteRecordError}</div>

                    <div onClick={handleCancelDeleteRecord} className='w-full text-[white] bg-[#ffffff20] mt-10 py-[6px] flex flex-row item-center justify-center rounded-[99px] hover:bg-[#ffffff30] hover:font-bold select-none cursor-pointer'>
                        Cancel
                    </div>
                    <div onClick={handleDeleteRecord} className='w-full text-[#FF969C] bg-[#FF969C20] mt-2 py-[6px] flex flex-row item-center justify-center rounded-[99px] hover:bg-[#FF969C30] hover:font-bold select-none cursor-pointer'>
                        Delete
                    </div>
                </div>
            </div>}
            {/*end of delete record popup*/}

            {/*remote unlock popup*/}
            {isUnlocking&&<div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)'}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative' style={{border:'.5px solid #FFFFFF80'}}>
                    <div className='flex flex-row items-center space-between w-full pr-[64px]'>
                        <div className='font-bold'>Remote Unlock</div>          
                    </div>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={handleDismissRemoteUnlock}>X</div>         

                    <div className='flex flex-row items-center space-between w-full mt-10'>
                        <div className='text-sm'>Serial Number: {isUnlocking?.id||'-'}</div>          
                    </div>
                    <div className='flex flex-row items-center space-between w-full mt-2'>
                        <div className='text-sm'>Device Name: {isUnlocking?.data?.name||'-'}</div>          
                    </div>

                    <div className='w-full mt-5 text-xs' style={{color:remoteUnlockMessage==='Unlock Success'?'#63BDFF':'red'}}>{remoteUnlockMessage}</div>

                    <div onClick={handleDismissRemoteUnlock} className='button_blue mt-10' style={{padding:'4px'}}>
                        OK
                    </div>
                    <div onClick={handleRemoteUnlock(isUnlocking)} className='button_white mt-2' style={{padding:'4px'}}>
                        Retry
                    </div>
                </div>
            </div>}
            {/*end of remote unlock popup*/}


            {isLoading&&<LoadingPage/>}
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(SmartLockP2)