import React, {useEffect, useState} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import {fetchProjectsList} from '../services/projects.service';
import {LANGUAGES} from '../dto/language'
import { Login, setlanguage, setpermissions, setprojectId, setuserData } from '../actions/actions';
import SettingSvg from '../svgs/Setting'
import ThemeSvg from '../svgs/Theme'
import TitleLeftSvg from '../svgs/TitleLeftSvg'
import Weather from './home/WeatherForecast'
import { fetchUserData } from '../services/users.service';
import { useCookies } from 'react-cookie';
import {COOKIESPREFIX} from '../config.js'

const Slash = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={10} height={16} fill="none" {...props}>
    <path stroke="#fff" strokeWidth={2} d="m8.882.471-8 15" />
  </svg>
)
const Slash2 = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={31} fill="none" {...props}>
    <path stroke="#fff" strokeWidth={0.5} d="m16.221.118-16 30" />
  </svg>
)

const LargeSlash = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg"  width={17}  height={31}  fill="none"  className='ml-[-16px] mr-[-16px]'  {...props}>
    <path stroke="#ffffff70" d="m16.221.118-16 30" />
  </svg>
)



function Component(props) {
  const navigate = useNavigate();
  const location = useLocation()
  const [curScreen, setCurScreen] = useState('/')
  const [cookies, setCookie, removeCookie] = useCookies([`@${COOKIESPREFIX}_cred`,`@${COOKIESPREFIX}_token`]);
  useEffect(() => {
    const pathName = location.pathname.split('/')[1]
    setCurScreen(`/${pathName}`)
  },[location])
  function navigating(str){
    // setCurScreen(str)
    navigate(str)
  }
  function logOut(){
    removeCookie(`@${COOKIESPREFIX}_cred`,{path:'/'});
    removeCookie(`@${COOKIESPREFIX}_token`,{path:'/'});
    props.dispatch(Login(false))
    navigate('/')
  }
  const navStyle = {
    current: { color:'#fff', fontWeight:700, borderBottom: null},
    other: { color: '#ffffff60', fontWeight:700, borderBottom: null}
  }
  const [now,setNow]=useState(new Date())
  useEffect(()=>{
    const timeInterval= setInterval(()=>{setNow(new Date())},1000*30) 
    return ()=>{clearInterval(timeInterval)}
  },[])

  const [isMenuExpanded,setisMenuExpanded]=useState(false)
  function toogleMenu(){setisMenuExpanded(!isMenuExpanded)}

  const [windowSize, setWindowSize] = useState(getWindowSize());
    function getWindowSize() {const {innerWidth, innerHeight} = window;return {innerWidth, innerHeight};}
    useEffect(()=>{
        function handleWindowResize() {setWindowSize(getWindowSize()); }
        window.addEventListener('resize', handleWindowResize);
        return () => {window.removeEventListener('resize', handleWindowResize);};
  },[])


  //projectes
  const [projectList,setprojectList]=useState([])
  const fetchProjects=async ()=>{
    let result = await fetchProjectsList(props.token?.token)
    if(result?.result){
      setprojectList(result?.result)
    }
  }
  useEffect(()=>{fetchProjects();},[])
  const [isSelectingProject,setisSelectingProject]=useState(false)
  const handleStartSelectProject=()=>{
    if(props.token?.usertype==='admin')setisSelectingProject(true);
    else setisSelectingProject(false)
  }
  const handleSelectProject=(item,index)=>()=>{
    props.dispatch(setprojectId(item?.name))
    setisSelectingProject(false)
  }
  //end of projects

  //user data
  const fetchData=async()=>{
    const result = await fetchUserData(props.token.token)
    if(result.errno===10001){
        props.dispatch(Login(false))
        return alert('Session Expired');
    }else if(result?.result){
        props.dispatch(setuserData(result?.result))
        props.dispatch(setpermissions(result?.permissions))
    }
  }
  useEffect(()=>{fetchData()},[props.token?.isLogin])
  //userdata

  return (
    <div className='relative text-white bg-black flex justify-start items-stretch flex-row w-full min-h-[60px]' style={{overflowX:'auto'}}>
        <div className='w-[120px] px-[27px] flex text-sm items-center justify-center' style={{borderRight:'.5px solid #aaa'}}>
          Novalinx
        </div>
        <div className='w-[240px] flex items-center justify-center px-[20px] text-xs relative'>
          <div onClick={handleStartSelectProject} className='cursor-pointer'>{props?.control?.projectId}</div>
          {props.token?.usertype==='admin'&&isSelectingProject&&
            <div className='absolute top-[100%] flex flex-col bg-black w-full items-center justify-center rounded-[8px]' style={{zIndex:95,border:'.5px solid #ffffff80'}}>
              {projectList?.map((item,index)=>
                <div onClick={handleSelectProject(item,index)} className='cursor-pointer py-[12px] text-white hover:bg-[#ffffff80] hover:text-black px-[20px] w-full text-center' key={item?.name||index}>
                  {item?.name}
                </div>
              )}
            </div>
          }
        </div>
        {/*end of todo add logic to show dropdown list only if user is a superadmin*/}
        <div className='flex flex-row items-center justify-center flex-1 gap-4 h-full text-sm'>
            <div className="cursor-pointer h-full flex flex-row items-center" onClick={() => {navigating('/')}} style={curScreen === '/' ? navStyle.current : navStyle.other}>
              {curScreen === '/'&&<Slash className='mr-2'/>}
              CMP
              {curScreen === '/'&&<Slash className='ml-2'/>}
            </div>
            <LargeSlash/>
            <div className="cursor-pointer h-full flex flex-row items-center" onClick={() => {navigating('/summary')}} style={curScreen === '/summary' ? navStyle.current : navStyle.other}>
              {curScreen === '/summary'&&<Slash className='mr-2'/>}
              Summary
              {curScreen === '/summary'&&<Slash className='ml-2'/>}
            </div>
            <LargeSlash/>
            <div className="cursor-pointer h-full flex flex-row items-center" onClick={() => {navigating('/tracking')}} style={curScreen === '/tracking' ? navStyle.current : navStyle.other}>
              {curScreen === '/tracking'&&<Slash className='mr-2'/>}
              Tracking
              {curScreen === '/tracking'&&<Slash className='ml-2'/>}
            </div>
            <LargeSlash/>
            <div className="cursor-pointer h-full flex flex-row items-center" onClick={() => {navigating('/permit')}} style={curScreen === '/permit' ? navStyle.current : navStyle.other}>
              {curScreen === '/permit'&&<Slash className='mr-2'/>}
              Permit-to-Work 
              {curScreen === '/permit'&&<Slash className='ml-2'/>}
            </div>
            <LargeSlash/>
            <div className="cursor-pointer h-full flex flex-row items-center" onClick={() => {navigating('/smartwatch')}} style={curScreen === '/smartwatch' ? navStyle.current : navStyle.other}>
              {curScreen === '/smartwatch'&&<Slash className='mr-2'/>}
              Smart Watch
              {curScreen === '/smartwatch'&&<Slash className='ml-2'/>}
            </div>
            <LargeSlash/>
            <div className="cursor-pointer h-full flex flex-row items-center" onClick={() => {navigating('/camera')}} style={curScreen === '/camera' ? navStyle.current : navStyle.other}>
              {curScreen === '/camera'&&<Slash className='mr-2'/>}
              AI Camera
              {curScreen === '/camera'&&<Slash className='ml-2'/>}
            </div>
      </div>
      <div className='flex flex-row items-center justify-center'>
          <div className='w-[60px] h-full flex items-center justify-center flex-col text-xs' style={{borderRight:'.5px solid #aaa'}}>
            <Weather now={now} nav={true}/>
          </div>
          {/* <div className='w-[60px] h-full flex items-center justify-center flex-col' style={{borderRight:'.5px solid #aaa'}}>
            <svg xmlns="http://www.w3.org/2000/svg" width={19} height={21} fill="none">
              <circle cx={9.78} cy={8.998} r={8.498} stroke="#fff" />
              <path stroke="#fff" d="M4.266 16.002.354 19.914" />
            </svg>
          </div> */}
          <div onClick={toogleMenu} className='w-[60px] h-full flex items-center justify-center flex-col cursor-pointer' style={{transition:'1s all ease',backgroundColor:isMenuExpanded?'white':'transparent'}}>
            {isMenuExpanded?
              <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line y1="-1" x2="28.1279" y2="-1" transform="matrix(-0.737373 -0.675486 0.666321 -0.745665 22.3461 19)" stroke="#0D0F12" strokeWidth="2"/>
                <line y1="-1" x2="27.7949" y2="-1" transform="matrix(-0.71066 0.703536 -0.694656 -0.719342 20.3704 0)" stroke="#0D0F12" strokeWidth="2"/>
              </svg>
              :<svg xmlns="http://www.w3.org/2000/svg" width={22} height={21}fill="none">
                <path stroke="#fff" d="M21.907 10.5H5.547M21.907 1.151H.094M21.907 19.848H.094"/>
              </svg>
            }
          </div>
      </div>
      {isSelectingProject&&
        <div className='absolute top-[60px] w-full' style={{height:windowSize.innerHeight-60,backgroundColor:"#00000090",zIndex:90}} onClick={()=>{setisSelectingProject(false)}}>
        </div>
      }
      {isMenuExpanded&&
        <div className='absolute top-[60px] w-full' style={{height:windowSize.innerHeight-60,backgroundColor:"#00000090",zIndex:99}}>
          <div className='absolute top-0 right-0 flex flex-col bg-black h-full text-white' style={{borderLeft:'1px solid white',padding:24,overflowY:'auto',minWidth:475}}>
            <div className='flex flex-row justify-between w-full pb-[8px]' style={{borderBottom:'1px solid #aaa'}}>
              <div className='flex flex-row gap-[24px] items-end'>
                Language:
                <div className='cursor-pointer text-sm' onClick={()=>{props.dispatch(setlanguage(LANGUAGES.EN))}} style={props.control?.language===LANGUAGES.EN?{fontWeight:'700',opacity:1}:{fontWeight:'500',opacity:.5}}>{LANGUAGES.EN}</div>
                <div className='cursor-pointer text-sm' onClick={()=>{props.dispatch(setlanguage(LANGUAGES.CH))}} style={props.control?.language===LANGUAGES.CH?{fontWeight:'700',opacity:1}:{fontWeight:'500',opacity:.5}}>{LANGUAGES.CH}</div>
              </div>
              <div className='flex flex-row items-center'>
                <ThemeSvg className='cursor-pointer'/>
                <SettingSvg className='cursor-pointer ml-3'/>
              </div>
            </div>
            <div className='flex flex-col w-full py-[8px]' style={{borderBottom:'1px solid #aaa'}}>
              Welcome, {props.control?.userData?.data?.firstname && props.control?.userData?.data?.lastname ? (props.control?.userData?.data?.firstname + ' ' + props.control?.userData?.data?.lastname + '!') : 'Back!'}
              <div className='flex flex-row w-full justify-between items-end'>
                <div className='text-xs font-[300]'>Your Identity : {props.control.userData?.username}</div>
                <div className='text-sm cursor-pointer font-bold'>My profile</div>
              </div>
            </div>
            {/* <div className='flex flex-col w-full py-[8px]'>
            
            </div> */}
            <div className='flex flex-row mt-10 text-base font-bold gap-[24px] cursor-pointer'>
              <TitleLeftSvg/>{props.control.projectId} Safety System
            </div>
            {/* <div className='flex flex-row mt-10 text-base gap-[24px] cursor-pointer hover:scale-105'>
              <Slash2/>Report Export
            </div> */}
            <div onClick={() => {navigating('/user-management');toogleMenu()}} className='flex flex-row mt-10 text-base gap-[24px] cursor-pointer hover:font-bold'>
              <Slash2/>User Management
            </div>
            <div className='flex flex-row mt-10 text-base gap-[24px] cursor-pointer hover:font-bold'>
              <Slash2/>Settings
            </div>
            <div onClick={logOut} className='flex flex-row mt-10 text-base gap-[24px] cursor-pointer hover:font-bold mt-auto'>
              <Slash2/>Logout
            </div>

          </div>

        </div>
      }
    </div>
  );
}

export default connect((store)=>{
  return{
    token:store.tokenReducer,
    control:store.controlReducer,
  }
})(Component)
